import React, { useState } from "react";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";

import { HowTo } from "./HowTo";

import { Form } from "./Form";

interface RegisterMenu {
  openRegisterMenu: {
    open: boolean;
    round: number | null;
  };
  handleOpenRegisterWhiteListMenu: (round?: number, close?: boolean) => void;
  getNbWhitelisted: () => void;
  setLogin: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RegisterWhiteList: React.FC<RegisterMenu> = ({ openRegisterMenu, handleOpenRegisterWhiteListMenu, getNbWhitelisted, setLogin }) => {
  const { open, round } = openRegisterMenu;

  const [step, setStep] = useState(0);

  const goToNextStep = () => {
    setStep((step) => step + 1);
  };

  const handleClose = () => {
    handleOpenRegisterWhiteListMenu(0, true);
    getNbWhitelisted();
    setStep(0);
  };

  const handleCloseGoLogIn = () => {
    handleOpenRegisterWhiteListMenu(0, true);
    getNbWhitelisted();
    setStep(0);
    setLogin(true);
  };

  const desktop = useMediaQuery("(min-width:600px)");

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          background: "linear-gradient(180deg, #0F0E1C 0%, #1C0F6B 100%)",
          color: "white",
          height: "100%",
          width: "100%",
          padding: "20px",
          maxWidth: "100%",
          marginRight: desktop ? "auto" : "5px",
          marginLeft: desktop ? "auto" : "5px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: "10px",
          position: "relative",
        },
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "40px" }}>
        <Typography
          style={{
            color: "#FFFFFF",
            fontFamily: "Alphakind",
            fontWeight: 400,
            fontSize: desktop ? "48px" : "32px",
            lineHeight: desktop ? "46px" : "30px",
            textAlign: "center",
            width: "75%",
          }}
        >
          {`Whitelist registration`}
        </Typography>
        <Typography
          style={{
            color: "#82C44C",
            fontFamily: "Nunito",
            fontWeight: 700,
            fontSize: desktop ? "32px" : "24px",
            lineHeight: desktop ? "44px" : "26px",
            textAlign: "center",
            marginTop: "15px",
            marginBottom: "15px",
          }}
        >
          {`Step ${step}/2`}
        </Typography>
      </div>

      {step === 0 ? <HowTo /> : null}

      {step === 1 && round ? <Form round={round} goToNextStep={goToNextStep} /> : null}
      {step === 2 && round ? (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Typography variant='h3' color='' style={{ marginBottom: "20px", fontFamily: "Alphakind", textAlign: "center" }}>
            Thanks for your registration!
          </Typography>
          <Typography variant='h4' color='primary' style={{ fontFamily: "Nunito", width: "75%", textAlign: "center" }}>
            Remember to check your emails (maybe your spams) to confirm your email address and receive our next information related to the
            MyLovelyPlanet and MyLovelyCoin project
          </Typography>
        </div>
      ) : null}
      <Button
        component='button'
        size='small'
        variant='outlined'
        onClick={handleClose}
        sx={{ position: "fixed", top: "20px", right: "20px", color: "white", borderColor: "white" }}
      >
        x
      </Button>

      {step === 0 ? (
        <Button component='button' size='large' variant='contained' color='primary' sx={{ marginTop: "15px" }} onClick={goToNextStep}>
          Next
        </Button>
      ) : null}
      {step === 2 ? (
        <Button component='button' size='large' variant='contained' color='primary' sx={{ marginTop: "15px" }} onClick={() => handleCloseGoLogIn()}>
          Close
        </Button>
      ) : null}
      {step !== 0 && step !== 2 ? (
        <Button
          sx={{ visibility: "hidden", marginTop: "15px" }}
          component='button'
          size='large'
          variant='contained'
          color='primary'
          onClick={handleClose}
        >
          Close
        </Button>
      ) : null}
    </Dialog>
  );
};
