import React, { useState, useEffect, useMemo } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import useMediaQuery from "@mui/material/useMediaQuery";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";

import { useLocation } from "react-router-dom";

//@ts-ignore
import RIB_MLP from "../assets/docs/RIB_MLP.pdf";

interface FormTransferProps {
  currency: string;
  xtzAddress?: string;
  goToNextStep: () => void;
  handleSetCurrency: (currency: string) => void;
}

export const FormTransfer: React.FC<FormTransferProps> = ({ goToNextStep, handleSetCurrency, currency, xtzAddress }) => {
  const [investorId, setInvestorId] = useState("");
  const desktop = useMediaQuery("(min-width:600px)");

  const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  };
  let query = useQuery();

  useEffect(() => {
    const id = query.get("id");
    if (id) {
      setInvestorId(id);
    }
  }, [query]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Typography
        color='primary'
        style={{
          maxWidth: "75%",
          textAlign: "center",
          fontFamily: "Nunito",
          fontSize: desktop ? "32px" : "24px",
          lineHeight: desktop ? "34px" : "26px",
        }}
      >
        Choose a currency
      </Typography>

      <Box
        component='form'
        style={{ padding: "20px 40px", borderRadius: 10, maxWidth: "80%" }}
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
      >
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <FormControl sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "30px" }}>
            <RadioGroup row aria-labelledby='currency' onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSetCurrency(e.target.value)} value={currency}>
              <FormControlLabel value='€' control={<Radio color='secondary' />} label={<Typography variant='h5'>€</Typography>} />
              <FormControlLabel value='$' control={<Radio color='secondary' />} label={<Typography variant='h5'>$</Typography>} />
              <FormControlLabel value='eth' control={<Radio color='secondary' />} label={<Typography variant='h5'>eth</Typography>} />
              <FormControlLabel value='matic' control={<Radio color='secondary' />} label={<Typography variant='h5'>matic</Typography>} />
              <FormControlLabel value='xtz' control={<Radio color='secondary' />} label={<Typography variant='h5'>usdt / usdc</Typography>} />
            </RadioGroup>
          </FormControl>
          <Typography
            color='primary'
            style={{
              maxWidth: "75%",
              textAlign: "center",
              fontFamily: "Nunito",
              fontSize: desktop ? "32px" : "24px",
              lineHeight: desktop ? "34px" : "26px",
              marginBottom: "20px",
              marginTop: "15px",
            }}
          >
            Then make your payment
          </Typography>
          <Typography
            style={{
              maxWidth: "75%",
              textAlign: "center",
              fontFamily: "Nunito",
              fontSize: desktop ? "24px" : "18px",
              lineHeight: desktop ? "26px" : "20px",
              marginBottom: "15px",
            }}
          >
            Max: 10 000€
          </Typography>

          {currency === "€" || currency === "$" ? (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "30px" }}>
              <Typography
                style={{
                  textAlign: "center",
                  fontFamily: "Nunito",
                  fontSize: desktop ? "24px" : "18px",
                  lineHeight: desktop ? "26px" : "20px",
                  marginBottom: "30px",
                  marginTop: "40px",
                  fontWeight: "bold",
                }}
              >
                Download the RIB{" "}
                <a target='_blank' href={RIB_MLP} rel='noreferrer' style={{ color: "#82C44C", fontFamily: "Nunito" }}>
                  here
                </a>{" "}
                to transfer the funds{" "}
              </Typography>

              <Typography
                style={{
                  textAlign: "center",
                  fontFamily: "Nunito",
                  fontSize: desktop ? "24px" : "18px",
                  lineHeight: desktop ? "26px" : "20px",
                  marginBottom: "15px",
                  marginTop: "10px",
                  fontWeight: "bold",
                }}
              >
                Please specify this reference in the wire transfer:
              </Typography>

              <Typography
                color='primary'
                style={{
                  textAlign: "center",
                  fontFamily: "Nunito",
                  fontSize: desktop ? "24px" : "18px",
                  lineHeight: desktop ? "26px" : "20px",
                  fontWeight: "bold",
                }}
              >
                {investorId}
              </Typography>
            </div>
          ) : null}
          {currency === "eth" || currency === "matic" || currency === "xtz" ? (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "30px" }}>
              <Typography
                style={{
                  textAlign: "center",
                  fontFamily: "Nunito",
                  fontSize: desktop ? "24px" : "18px",
                  lineHeight: desktop ? "26px" : "20px",
                  marginBottom: "15px",
                  width: "90%",
                }}
              >
                Our public key is:
              </Typography>
              <Typography
                color='primary'
                style={{
                  textAlign: "center",
                  fontFamily: "Nunito",
                  fontSize: desktop ? "24px" : "12px",
                  lineHeight: desktop ? "26px" : "20px",
                  marginBottom: "30px",
                  width: "90%",
                }}
              >
                {currency === "xtz" ? "0x0d24e5b142cdBb0f9F9c5B317e4177506d6200ac" : "0x0d24e5b142cdBb0f9F9c5B317e4177506d6200ac"}
              </Typography>
              <Typography
                style={{
                  textAlign: "center",
                  fontFamily: "Nunito",
                  fontSize: desktop ? "18px" : "16px",
                  lineHeight: desktop ? "20px" : "18px",
                  marginBottom: "15px",
                  width: "90%",
                }}
              >
                Reminder: You must pay with the public address you gave in your whitelist which is:
              </Typography>
              <Typography
                color='primary'
                style={{
                  textAlign: "center",
                  fontFamily: "Nunito",
                  fontSize: desktop ? "24px" : "12px",
                  lineHeight: desktop ? "26px" : "20px",
                  width: "90%",
                }}
              >
                {xtzAddress}
              </Typography>
            </div>
          ) : null}
        </div>
      </Box>
    </div>
  );
};
