import React, { useEffect, useState } from "react";
import "./App.css";

import dayjs, { Dayjs } from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";

import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import CircularProgress from "@mui/material/CircularProgress";
import { TextField } from "@mui/material";

export const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#82C44C",
    },
    secondary: {
      main: "#FFFFFF",
    },
    info: {
      main: "#469108",
    },
  },
});

export type User = {
  KYC_STATUS: string;
  IDENTITY: string;
  LIVENESS: string;
  RESIDENCY: string;
  currency: string;
  confirmedCurrency?: string;
  date: number;
  discount?: boolean;
  email: string;
  firstName: string;
  lastName: string;
  id: string;
  investment?: number;
  confirmedInvestment?: number;
  investmentConfirmed?: boolean;
  investmentValidated?: boolean;
  invitationCode?: string;
  phoneNumber: string;
  resetPassword: boolean;
  round: number;
  synapsSession?: string;
  userInvitationCode?: string;
  xtzAddress?: string;
};

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  handleRateChange: () => Promise<void>;
}

function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, open, handleRateChange } = props;
  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Etes vous sûr de vouloir modifier les taux?</DialogTitle>
      <Button onClick={handleRateChange}>Modifier</Button>
      <Button onClick={handleClose}>Annuler</Button>
    </Dialog>
  );
}

function Adm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [logged, setLogged] = useState(false);
  const [userList, setUserList] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [mlcXtz1, setMlcXtz1] = useState(0);
  const [mlcXtz2, setMlcXtz2] = useState(0);
  const [mlcXtz3, setMlcXtz3] = useState(0);
  const [mlcXtz4, setMlcXtz4] = useState(0);
  const [mlcEth1, setMlcEth1] = useState(0);
  const [mlcEth2, setMlcEth2] = useState(0);
  const [mlcEth3, setMlcEth3] = useState(0);
  const [mlcEth4, setMlcEth4] = useState(0);
  const [mlcMatic1, setMlcMatic1] = useState(0);
  const [mlcMatic2, setMlcMatic2] = useState(0);
  const [mlcMatic3, setMlcMatic3] = useState(0);
  const [mlcMatic4, setMlcMatic4] = useState(0);
  const [pledged, setPledged] = useState<{
    round1Eur: number;
    round2Eur: number;
    round3Eur: number;
    round4Eur: number;
    round1Dol: number;
    round2Dol: number;
    round3Dol: number;
    round4Dol: number;
    round1Xtz: number;
    round2Xtz: number;
    round3Xtz: number;
    round4Xtz: number;
    round1Eth: number;
    round2Eth: number;
    round3Eth: number;
    round4Eth: number;
    round1Matic: number;
    round2Matic: number;
    round3Matic: number;
    round4Matic: number;
  }>({
    round1Eur: 0,
    round2Eur: 0,
    round3Eur: 0,
    round4Eur: 0,
    round1Dol: 0,
    round2Dol: 0,
    round3Dol: 0,
    round4Dol: 0,
    round1Xtz: 0,
    round2Xtz: 0,
    round3Xtz: 0,
    round4Xtz: 0,
    round1Eth: 0,
    round2Eth: 0,
    round3Eth: 0,
    round4Eth: 0,
    round1Matic: 0,
    round2Matic: 0,
    round3Matic: 0,
    round4Matic: 0,
  });
  const [confirmed, setConfirmed] = useState<{
    round1Eur: number;
    round2Eur: number;
    round3Eur: number;
    round4Eur: number;
    round1Dol: number;
    round2Dol: number;
    round3Dol: number;
    round4Dol: number;
    round1Xtz: number;
    round2Xtz: number;
    round3Xtz: number;
    round4Xtz: number;
    round1Eth: number;
    round2Eth: number;
    round3Eth: number;
    round4Eth: number;
    round1Matic: number;
    round2Matic: number;
    round3Matic: number;
    round4Matic: number;
  }>({
    round1Eur: 0,
    round2Eur: 0,
    round3Eur: 0,
    round4Eur: 0,
    round1Dol: 0,
    round2Dol: 0,
    round3Dol: 0,
    round4Dol: 0,
    round1Xtz: 0,
    round2Xtz: 0,
    round3Xtz: 0,
    round4Xtz: 0,
    round1Eth: 0,
    round2Eth: 0,
    round3Eth: 0,
    round4Eth: 0,
    round1Matic: 0,
    round2Matic: 0,
    round3Matic: 0,
    round4Matic: 0,
  });

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogin = async () => {
    try {
      if (!email || !password) {
        console.log("Data missing");
        return;
      }

      setLoading(true);

      const login = await fetch(`${process.env.REACT_APP_API_URL}/admin-login`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          email,
          password,
        }),
      });
      const loginResponse = await login.json();
      setLoading(false);

      if (
        loginResponse?.data?.status === "authorized" &&
        loginResponse?.data?.token
      ) {
        localStorage.setItem("token", loginResponse?.data?.token);
        setLogged(true);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleGetUserList = async () => {
    const token = localStorage.getItem("token");
    try {
      setLoading(true);
      const userListRequest = await fetch(`${process.env.REACT_APP_API_URL}/user-list?token=${token}`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "GET",
      });

      const userListResponse = await userListRequest.json();
      if (userListResponse?.data?.length) {
        const { data } = userListResponse;
        const sortedUserList = data.sort((userA: User, userB: User) => {
          if (userA.round > userB.round) {
            return 1;
          }
          if (userA.round < userB.round) {
            return -1;
          }
          if (userA.date > userB.date) {
            return 1;
          }
          if (userA.date < userB.date) {
            return -1;
          }
          return 1;
        });
        setUserList(sortedUserList);
        setFilteredUsers(sortedUserList);

        // Pledged €
        const usersRound1Eur = data.filter((user: User) => {
          return user.currency === "€" && user.round === 1;
        });
        const round1EurPledged = usersRound1Eur.reduce(
          (acc: number, user: User) => {
            return acc + (user?.investment || 0);
          },
          0
        );

        const usersRound2Eur = data.filter((user: User) => {
          return user.currency === "€" && user.round === 2;
        });
        const round2EurPledged = usersRound2Eur.reduce(
          (acc: number, user: User) => {
            return acc + (user?.investment || 0);
          },
          0
        );

        const usersRound3Eur = data.filter((user: User) => {
          return user.currency === "€" && user.round === 3;
        });
        const round3EurPledged = usersRound3Eur.reduce(
          (acc: number, user: User) => {
            return acc + (user?.investment || 0);
          },
          0
        );
        const usersRound4Eur = data.filter((user: User) => {
          return user.currency === "€" && user.round === 4;
        });
        const round4EurPledged = usersRound4Eur.reduce(
          (acc: number, user: User) => {
            return acc + (user?.investment || 0);
          },
          0
        );

        // Pledged $

        const usersRound1Dol = data.filter((user: User) => {
          return user.currency === "$" && user.round === 1;
        });
        const round1DolPledged = usersRound1Dol.reduce(
          (acc: number, user: User) => {
            return acc + (user?.investment || 0);
          },
          0
        );

        const usersRound2Dol = data.filter((user: User) => {
          return user.currency === "$" && user.round === 2;
        });
        const round2DolPledged = usersRound2Dol.reduce(
          (acc: number, user: User) => {
            return acc + (user?.investment || 0);
          },
          0
        );

        const usersRound3Dol = data.filter((user: User) => {
          return user.currency === "$" && user.round === 3;
        });
        const round3DolPledged = usersRound3Dol.reduce(
          (acc: number, user: User) => {
            return acc + (user?.investment || 0);
          },
          0
        );

        const usersRound4Dol = data.filter((user: User) => {
          return user.currency === "$" && user.round === 4;
        });
        const round4DolPledged = usersRound4Dol.reduce(
          (acc: number, user: User) => {
            return acc + (user?.investment || 0);
          },
          0
        );

        // Pledged xtz

        const usersRound1Xtz = data.filter((user: User) => {
          return user.currency === "xtz" && user.round === 1;
        });
        const round1XtzPledged = usersRound1Xtz.reduce(
          (acc: number, user: User) => {
            return acc + (user?.investment || 0);
          },
          0
        );

        const usersRound2Xtz = data.filter((user: User) => {
          return user.currency === "xtz" && user.round === 2;
        });
        const round2XtzPledged = usersRound2Xtz.reduce(
          (acc: number, user: User) => {
            return acc + (user?.investment || 0);
          },
          0
        );

        const usersRound3Xtz = data.filter((user: User) => {
          return user.currency === "xtz" && user.round === 3;
        });
        const round3XtzPledged = usersRound3Xtz.reduce(
          (acc: number, user: User) => {
            return acc + (user?.investment || 0);
          },
          0
        );

        const usersRound4Xtz = data.filter((user: User) => {
          return user.currency === "xtz" && user.round === 4;
        });
        const round4XtzPledged = usersRound4Xtz.reduce(
          (acc: number, user: User) => {
            return acc + (user?.investment || 0);
          },
          0
        );

        // Pledged eth

        const usersRound1Eth = data.filter((user: User) => {
          return user.currency === "eth" && user.round === 1;
        });
        const round1EthPledged = usersRound1Eth.reduce(
          (acc: number, user: User) => {
            return acc + (user?.investment || 0);
          },
          0
        );

        const usersRound2Eth = data.filter((user: User) => {
          return user.currency === "eth" && user.round === 2;
        });
        const round2EthPledged = usersRound2Eth.reduce(
          (acc: number, user: User) => {
            return acc + (user?.investment || 0);
          },
          0
        );

        const usersRound3Eth = data.filter((user: User) => {
          return user.currency === "eth" && user.round === 3;
        });
        const round3EthPledged = usersRound3Eth.reduce(
          (acc: number, user: User) => {
            return acc + (user?.investment || 0);
          },
          0
        );

        const usersRound4Eth = data.filter((user: User) => {
          return user.currency === "eth" && user.round === 4;
        });
        const round4EthPledged = usersRound4Eth.reduce(
          (acc: number, user: User) => {
            return acc + (user?.investment || 0);
          },
          0
        );

        // Pledged Matic

        const usersRound1Matic = data.filter((user: User) => {
          return user.currency === "matic" && user.round === 1;
        });
        const round1MaticPledged = usersRound1Matic.reduce(
          (acc: number, user: User) => {
            return acc + (user?.investment || 0);
          },
          0
        );

        const usersRound2Matic = data.filter((user: User) => {
          return user.currency === "matic" && user.round === 2;
        });
        const round2MaticPledged = usersRound2Matic.reduce(
          (acc: number, user: User) => {
            return acc + (user?.investment || 0);
          },
          0
        );

        const usersRound3Matic = data.filter((user: User) => {
          return user.currency === "matic" && user.round === 3;
        });
        const round3MaticPledged = usersRound3Matic.reduce(
          (acc: number, user: User) => {
            return acc + (user?.investment || 0);
          },
          0
        );

        const usersRound4Matic = data.filter((user: User) => {
          return user.currency === "matic" && user.round === 4;
        });
        const round4MaticPledged = usersRound4Matic.reduce(
          (acc: number, user: User) => {
            return acc + (user?.investment || 0);
          },
          0
        );

        setPledged({
          round1Eur: round1EurPledged,
          round2Eur: round2EurPledged,
          round3Eur: round3EurPledged,
          round4Eur: round4EurPledged,
          round1Dol: round1DolPledged,
          round2Dol: round2DolPledged,
          round3Dol: round3DolPledged,
          round4Dol: round4DolPledged,
          round1Xtz: round1XtzPledged,
          round2Xtz: round2XtzPledged,
          round3Xtz: round3XtzPledged,
          round4Xtz: round4XtzPledged,
          round1Eth: round1EthPledged,
          round2Eth: round2EthPledged,
          round3Eth: round3EthPledged,
          round4Eth: round4EthPledged,
          round1Matic: round1MaticPledged,
          round2Matic: round2MaticPledged,
          round3Matic: round3MaticPledged,
          round4Matic: round4MaticPledged,
        });

        // Confirmed €
        const usersRound1EurConfirmed = data.filter((user: User) => {
          return user.confirmedCurrency === "€" && user.round === 1;
        });
        const round1EurConfirmed = usersRound1EurConfirmed.reduce(
          (acc: number, user: User) => {
            return acc + (user?.confirmedInvestment || 0);
          },
          0
        );

        const usersRound2EurConfirmed = data.filter((user: User) => {
          return user.confirmedCurrency === "€" && user.round === 2;
        });
        const round2EurConfirmed = usersRound2EurConfirmed.reduce(
          (acc: number, user: User) => {
            return acc + (user?.confirmedInvestment || 0);
          },
          0
        );

        const usersRound3EurConfirmed = data.filter((user: User) => {
          return user.confirmedCurrency === "€" && user.round === 3;
        });
        const round3EurConfirmed = usersRound3EurConfirmed.reduce(
          (acc: number, user: User) => {
            return acc + (user?.confirmedInvestment || 0);
          },
          0
        );

        const usersRound4EurConfirmed = data.filter((user: User) => {
          return user.confirmedCurrency === "€" && user.round === 4;
        });
        const round4EurConfirmed = usersRound4EurConfirmed.reduce(
          (acc: number, user: User) => {
            return acc + (user?.confirmedInvestment || 0);
          },
          0
        );

        // Confirmed $

        const usersRound1DolConfirmed = data.filter((user: User) => {
          return user.confirmedCurrency === "$" && user.round === 1;
        });
        const round1DolConfirmed = usersRound1DolConfirmed.reduce(
          (acc: number, user: User) => {
            return acc + (user?.confirmedInvestment || 0);
          },
          0
        );

        const usersRound2DolConfirmed = data.filter((user: User) => {
          return user.confirmedCurrency === "$" && user.round === 2;
        });
        const round2DolConfirmed = usersRound2DolConfirmed.reduce(
          (acc: number, user: User) => {
            return acc + (user?.confirmedInvestment || 0);
          },
          0
        );

        const usersRound3DolConfirmed = data.filter((user: User) => {
          return user.confirmedCurrency === "$" && user.round === 3;
        });
        const round3DolConfirmed = usersRound3DolConfirmed.reduce(
          (acc: number, user: User) => {
            return acc + (user?.confirmedInvestment || 0);
          },
          0
        );

        const usersRound4DolConfirmed = data.filter((user: User) => {
          return user.confirmedCurrency === "$" && user.round === 4;
        });
        const round4DolConfirmed = usersRound4DolConfirmed.reduce(
          (acc: number, user: User) => {
            return acc + (user?.confirmedInvestment || 0);
          },
          0
        );

        // Confirmed xtz

        const usersRound1XtzConfirmed = data.filter((user: User) => {
          return user.confirmedCurrency === "xtz" && user.round === 1;
        });
        const round1XtzConfirmed = usersRound1XtzConfirmed.reduce(
          (acc: number, user: User) => {
            return acc + (user?.confirmedInvestment || 0);
          },
          0
        );

        const usersRound2XtzConfirmed = data.filter((user: User) => {
          return user.confirmedCurrency === "xtz" && user.round === 2;
        });
        const round2XtzConfirmed = usersRound2XtzConfirmed.reduce(
          (acc: number, user: User) => {
            return acc + (user?.confirmedInvestment || 0);
          },
          0
        );

        const usersRound3XtzConfirmed = data.filter((user: User) => {
          return user.confirmedCurrency === "xtz" && user.round === 3;
        });
        const round3XtzConfirmed = usersRound3XtzConfirmed.reduce(
          (acc: number, user: User) => {
            return acc + (user?.confirmedInvestment || 0);
          },
          0
        );

        const usersRound4XtzConfirmed = data.filter((user: User) => {
          return user.confirmedCurrency === "xtz" && user.round === 4;
        });
        const round4XtzConfirmed = usersRound4XtzConfirmed.reduce(
          (acc: number, user: User) => {
            return acc + (user?.confirmedInvestment || 0);
          },
          0
        );

        // Confirmed eth

        const usersRound1EthConfirmed = data.filter((user: User) => {
          return user.confirmedCurrency === "eth" && user.round === 1;
        });
        const round1EthConfirmed = usersRound1EthConfirmed.reduce(
          (acc: number, user: User) => {
            return acc + (user?.confirmedInvestment || 0);
          },
          0
        );

        const usersRound2EthConfirmed = data.filter((user: User) => {
          return user.confirmedCurrency === "eth" && user.round === 2;
        });
        const round2EthConfirmed = usersRound2EthConfirmed.reduce(
          (acc: number, user: User) => {
            return acc + (user?.confirmedInvestment || 0);
          },
          0
        );

        const usersRound3EthConfirmed = data.filter((user: User) => {
          return user.confirmedCurrency === "eth" && user.round === 3;
        });
        const round3EthConfirmed = usersRound3EthConfirmed.reduce(
          (acc: number, user: User) => {
            return acc + (user?.confirmedInvestment || 0);
          },
          0
        );

        const usersRound4EthConfirmed = data.filter((user: User) => {
          return user.confirmedCurrency === "eth" && user.round === 4;
        });
        const round4EthConfirmed = usersRound4EthConfirmed.reduce(
          (acc: number, user: User) => {
            return acc + (user?.confirmedInvestment || 0);
          },
          0
        );

        // Confirmed matic

        const usersRound1MaticConfirmed = data.filter((user: User) => {
          return user.confirmedCurrency === "matic" && user.round === 1;
        });
        const round1MaticConfirmed = usersRound1MaticConfirmed.reduce(
          (acc: number, user: User) => {
            return acc + (user?.confirmedInvestment || 0);
          },
          0
        );

        const usersRound2MaticConfirmed = data.filter((user: User) => {
          return user.confirmedCurrency === "matic" && user.round === 2;
        });
        const round2MaticConfirmed = usersRound2MaticConfirmed.reduce(
          (acc: number, user: User) => {
            return acc + (user?.confirmedInvestment || 0);
          },
          0
        );

        const usersRound3MaticConfirmed = data.filter((user: User) => {
          return user.confirmedCurrency === "matic" && user.round === 3;
        });
        const round3MaticConfirmed = usersRound3MaticConfirmed.reduce(
          (acc: number, user: User) => {
            return acc + (user?.confirmedInvestment || 0);
          },
          0
        );

        const usersRound4MaticConfirmed = data.filter((user: User) => {
          return user.confirmedCurrency === "matic" && user.round === 4;
        });
        const round4MaticConfirmed = usersRound4MaticConfirmed.reduce(
          (acc: number, user: User) => {
            return acc + (user?.confirmedInvestment || 0);
          },
          0
        );

        setConfirmed({
          round1Eur: round1EurConfirmed,
          round2Eur: round2EurConfirmed,
          round3Eur: round3EurConfirmed,
          round4Eur: round4EurConfirmed,
          round1Dol: round1DolConfirmed,
          round2Dol: round2DolConfirmed,
          round3Dol: round3DolConfirmed,
          round4Dol: round4DolConfirmed,
          round1Xtz: round1XtzConfirmed,
          round2Xtz: round2XtzConfirmed,
          round3Xtz: round3XtzConfirmed,
          round4Xtz: round4XtzConfirmed,
          round1Eth: round1EthConfirmed,
          round2Eth: round2EthConfirmed,
          round3Eth: round3EthConfirmed,
          round4Eth: round4EthConfirmed,
          round1Matic: round1MaticConfirmed,
          round2Matic: round2MaticConfirmed,
          round3Matic: round3MaticConfirmed,
          round4Matic: round4MaticConfirmed,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (logged) {
      handleGetUserList();
    }
  }, [logged]);

  useEffect(() => {
    if (!userList) {
      return;
    }
    if (searchTerm) {
      const newFilteredUsers = userList?.filter((user: User) => {
        return (
          user?.email?.toLowerCase()?.includes(searchTerm) ||
          user?.firstName?.toLowerCase()?.includes(searchTerm) ||
          user?.lastName?.toLowerCase()?.includes(searchTerm) ||
          user?.id?.toLowerCase()?.includes(searchTerm)
        );
      });

      setFilteredUsers(newFilteredUsers);
    } else {
      setFilteredUsers(userList);
    }
  }, [searchTerm, userList]);

  const handleValidateUserInvest = async (id: string) => {
    const token = localStorage.getItem("token");
    try {
      const validateUserInvestRequest = await fetch(
        `${process.env.REACT_APP_API_URL}/validate-user-invest?token=${token}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            id,
          }),
        }
      );

      const validateUserInvestResponse = await validateUserInvestRequest.json();
      console.log(validateUserInvestResponse);
    } catch (error) {
      console.log(error);
    }
    handleGetUserList();
  };

  const handleGetInvestmentRound = async (round: number) => {
    try {
      const investRoundRequest = await fetch(
        `${process.env.REACT_APP_API_URL}/investmentRound?round=${round}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: "GET",
        }
      );

      const investRoundResponse = await investRoundRequest.json();

      if (round === 1) {
        setMlcXtz1(investRoundResponse.data.round.MLPXTZ);
        setMlcEth1(investRoundResponse.data.round.MLCETH);
        setMlcMatic1(investRoundResponse.data.round.MLCMATIC);
      }
      if (round === 2) {
        setMlcXtz2(investRoundResponse.data.round.MLPXTZ);
        setMlcEth2(investRoundResponse.data.round.MLCETH);
        setMlcMatic2(investRoundResponse.data.round.MLCMATIC);
      }
      if (round === 3) {
        setMlcXtz3(investRoundResponse.data.round.MLPXTZ);
        setMlcEth3(investRoundResponse.data.round.MLCETH);
        setMlcMatic3(investRoundResponse.data.round.MLCMATIC);
      }
      if (round === 4) {
        setMlcXtz4(investRoundResponse.data.round.MLPXTZ);
        setMlcEth4(investRoundResponse.data.round.MLCETH);
        setMlcMatic4(investRoundResponse.data.round.MLCMATIC);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetInvestmentRound(1);
    handleGetInvestmentRound(2);
    handleGetInvestmentRound(3);
    handleGetInvestmentRound(4);
  }, []);

  const handleRateChange = async () => {
    const token = localStorage.getItem("token");
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/changeRoundInvestRate?token=${token}`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          mlcXtz1,
          mlcXtz2,
          mlcXtz3,
          mlcXtz4,
          mlcEth1,
          mlcEth2,
          mlcEth3,
          mlcEth4,
          mlcMatic1,
          mlcMatic2,
          mlcMatic3,
          mlcMatic4,
        }),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const inputStyle = { WebkitBoxShadow: "0 0 0 1000px #171535 inset" };
  return (
    <ThemeProvider theme={theme}>
      <div style={{ background: "#0b0c22" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            background: "linear-gradient(180deg, #0F0E1C 0%, #1C0F6B 100%)",
            overflowX: "scroll",

            minHeight: "100vh",
          }}
        >
          {logged ? (
            <div style={{ margin: "50px 10px" }}>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    border: "1px solid white",
                    padding: "15px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    maxWidth: "500px",
                  }}
                >
                  <Typography
                    color="secondary"
                    style={{ marginBottom: "15px" }}
                  >
                    {" "}
                    My Lovely Planet Backoffice
                  </Typography>
                  <TextField
                    label="search"
                    color="secondary"
                    inputProps={{ style: inputStyle }}
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSearchTerm(e.target.value)
                    }
                    style={{ width: "200px" }}
                  />
                  {filteredUsers && filteredUsers.length ? (
                    <>
                      <Typography color="primary" style={{ margin: "10px 0" }}>
                        {filteredUsers.length} investors
                      </Typography>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          color="secondary"
                          style={{ margin: "20px  0 10px 0" }}
                        >
                          Pledged:
                        </Typography>
                        <div
                          style={{ display: "flex", justifyContent: "start" }}
                        >
                          <Typography
                            color="secondary"
                            style={{ margin: "0 10px " }}
                          >
                            Round 1
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {pledged.round1Eur} €
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {pledged.round1Dol} $
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {pledged.round1Xtz} xtz
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {pledged.round1Eth} eth
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {pledged.round1Matic} matic
                          </Typography>
                        </div>
                        <div
                          style={{ display: "flex", justifyContent: "start" }}
                        >
                          <Typography
                            color="secondary"
                            style={{ margin: "0 10px " }}
                          >
                            Round 2
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {pledged.round2Eur} €
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {pledged.round2Dol} $
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {pledged.round2Xtz} xtz
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {pledged.round2Eth} eth
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {pledged.round2Matic} matic
                          </Typography>
                        </div>
                        <div
                          style={{ display: "flex", justifyContent: "start" }}
                        >
                          <Typography
                            color="secondary"
                            style={{ margin: "0 10px " }}
                          >
                            Round 3
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {pledged.round3Eur} €
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {pledged.round3Dol} $
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {pledged.round3Xtz} xtz
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {pledged.round3Eth} eth
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {pledged.round3Matic} matic
                          </Typography>
                        </div>
                        <div
                          style={{ display: "flex", justifyContent: "start" }}
                        >
                          <Typography
                            color="secondary"
                            style={{ margin: "0 10px " }}
                          >
                            Round 4
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {pledged.round4Eur} €
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {pledged.round4Dol} $
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {pledged.round4Xtz} xtz
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {pledged.round4Eth} eth
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {pledged.round4Matic} matic
                          </Typography>
                        </div>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          color="secondary"
                          style={{ margin: "20px  0 10px 0" }}
                        >
                          Confirmed:
                        </Typography>
                        <div
                          style={{ display: "flex", justifyContent: "start" }}
                        >
                          <Typography
                            color="secondary"
                            style={{ margin: "0 10px " }}
                          >
                            Round 1
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {confirmed.round1Eur} €
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {confirmed.round1Dol} $
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {confirmed.round1Xtz} xtz
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {confirmed.round1Eth} eth
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {confirmed.round1Matic} matic
                          </Typography>
                        </div>
                        <div
                          style={{ display: "flex", justifyContent: "start" }}
                        >
                          <Typography
                            color="secondary"
                            style={{ margin: "0 10px " }}
                          >
                            Round 2
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {confirmed.round2Eur} €
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {confirmed.round2Dol} $
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {confirmed.round2Xtz} xtz
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {confirmed.round2Eth} eth
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {confirmed.round2Matic} matic
                          </Typography>
                        </div>
                        <div
                          style={{ display: "flex", justifyContent: "start" }}
                        >
                          <Typography
                            color="secondary"
                            style={{ margin: "0 10px " }}
                          >
                            Round 3
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {confirmed.round3Eur} €
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {confirmed.round3Dol} $
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {confirmed.round3Xtz} xtz
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {confirmed.round3Eth} eth
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {confirmed.round3Matic} matic
                          </Typography>
                        </div>
                        <div
                          style={{ display: "flex", justifyContent: "start" }}
                        >
                          <Typography
                            color="secondary"
                            style={{ margin: "0 10px " }}
                          >
                            Round 4
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {confirmed.round4Eur} €
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {confirmed.round4Dol} $
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {confirmed.round4Xtz} xtz
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {confirmed.round4Eth} eth
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ margin: "0 10px " }}
                          >
                            {confirmed.round4Matic} matic
                          </Typography>
                        </div>
                      </div>
                      <Typography
                        color="secondary"
                        style={{ margin: "20px  0 10px 0" }}
                      >
                        MLC / XTZ
                      </Typography>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <TextField
                          id="round1"
                          color="secondary"
                          label="Round 1"
                          type="number"
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            inputMode: "numeric",
                            style: inputStyle,
                          }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setMlcXtz1(parseFloat(e.target.value))
                          }
                          value={mlcXtz1}
                          key="round1"
                        />
                        <TextField
                          id="round2"
                          color="secondary"
                          label="Round 2"
                          type="number"
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            inputMode: "numeric",
                            style: inputStyle,
                          }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setMlcXtz2(parseFloat(e.target.value))
                          }
                          value={mlcXtz2}
                          key="round2"
                        />
                        <TextField
                          id="round3"
                          color="secondary"
                          label="Round 3"
                          type="number"
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            inputMode: "numeric",
                            style: inputStyle,
                          }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setMlcXtz3(parseFloat(e.target.value))
                          }
                          value={mlcXtz3}
                          key="round3"
                        />
                        <TextField
                          id="round4"
                          color="secondary"
                          label="Round 4"
                          type="number"
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            inputMode: "numeric",
                            style: inputStyle,
                          }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setMlcXtz4(parseFloat(e.target.value))
                          }
                          value={mlcXtz4}
                          key="round4"
                        />
                      </div>
                      <Typography
                        color="secondary"
                        style={{ margin: "20px  0 10px 0" }}
                      >
                        MLC / ETH
                      </Typography>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <TextField
                          id="round1"
                          color="secondary"
                          label="Round 1"
                          type="number"
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            inputMode: "numeric",
                            style: inputStyle,
                          }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setMlcEth1(parseFloat(e.target.value))
                          }
                          value={mlcEth1}
                          key="round1"
                        />
                        <TextField
                          id="round2"
                          color="secondary"
                          label="Round 2"
                          type="number"
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            inputMode: "numeric",
                            style: inputStyle,
                          }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setMlcEth2(parseFloat(e.target.value))
                          }
                          value={mlcEth2}
                          key="round2"
                        />
                        <TextField
                          id="round3"
                          color="secondary"
                          label="Round 3"
                          type="number"
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            inputMode: "numeric",
                            style: inputStyle,
                          }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setMlcEth3(parseFloat(e.target.value))
                          }
                          value={mlcEth3}
                          key="round3"
                        />
                        <TextField
                          id="round4"
                          color="secondary"
                          label="Round 4"
                          type="number"
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            inputMode: "numeric",
                            style: inputStyle,
                          }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setMlcEth4(parseFloat(e.target.value))
                          }
                          value={mlcEth4}
                          key="round4"
                        />
                      </div>
                      <Typography
                        color="secondary"
                        style={{ margin: "20px  0 10px 0" }}
                      >
                        MLC / MATIC
                      </Typography>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <TextField
                          id="round1"
                          color="secondary"
                          label="Round 1"
                          type="number"
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            inputMode: "numeric",
                            style: inputStyle,
                          }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setMlcMatic1(parseFloat(e.target.value))
                          }
                          value={mlcMatic1}
                          key="round1"
                        />
                        <TextField
                          id="round2"
                          color="secondary"
                          label="Round 2"
                          type="number"
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            inputMode: "numeric",
                            style: inputStyle,
                          }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setMlcMatic2(parseFloat(e.target.value))
                          }
                          value={mlcMatic2}
                          key="round2"
                        />
                        <TextField
                          id="round3"
                          color="secondary"
                          label="Round 3"
                          type="number"
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            inputMode: "numeric",
                            style: inputStyle,
                          }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setMlcMatic3(parseFloat(e.target.value))
                          }
                          value={mlcMatic3}
                          key="round3"
                        />
                        <TextField
                          id="round4"
                          color="secondary"
                          label="Round 4"
                          type="number"
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            inputMode: "numeric",
                            style: inputStyle,
                          }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setMlcMatic4(parseFloat(e.target.value))
                          }
                          value={mlcMatic4}
                          key="round4"
                        />
                      </div>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleClickOpen}
                        style={{ marginTop: "10px" }}
                      >
                        Modify rate change
                      </Button>
                      <SimpleDialog
                        handleRateChange={handleRateChange}
                        open={open}
                        onClose={handleClose}
                      />
                    </>
                  ) : null}
                </div>

                <div
                  style={{
                    marginLeft: 10,
                    border: "1px solid white",
                    padding: "15px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    maxWidth: "500px",
                  }}
                >
                  <CreateRound />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: "20px",
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    border: "1px solid white",
                    padding: "3px 10px",
                    width: "195px",
                  }}
                >
                  {" "}
                  <Typography color="primary">id</Typography>
                </div>
                <div
                  style={{
                    border: "1px solid white",
                    padding: "3px 10px",
                    width: "230px",
                  }}
                >
                  {" "}
                  <Typography color="primary">email</Typography>
                </div>
                <div
                  style={{
                    border: "1px solid white",
                    padding: "3px 10px",
                    width: "150px",
                  }}
                >
                  {" "}
                  <Typography color="primary">phone</Typography>
                </div>
                <div
                  style={{
                    border: "1px solid white",
                    padding: "3px 10px",
                    width: "100px",
                  }}
                >
                  {" "}
                  <Typography color="primary">Created at</Typography>
                </div>
                <div
                  style={{
                    border: "1px solid white",
                    padding: "3px 10px",
                    width: "50px",
                  }}
                >
                  {" "}
                  <Typography color="primary">round</Typography>
                </div>
                <div
                  style={{
                    border: "1px solid white",
                    padding: "3px 10px",
                    width: "75px",
                  }}
                >
                  {" "}
                  <Typography color="primary">discount</Typography>
                </div>
                <div
                  style={{
                    border: "1px solid white",
                    padding: "3px 10px",
                    width: "100px",
                  }}
                >
                  {" "}
                  <Typography color="primary">prénom</Typography>
                </div>
                <div
                  style={{
                    border: "1px solid white",
                    padding: "3px 10px",
                    width: "100px",
                  }}
                >
                  {" "}
                  <Typography color="primary">nom</Typography>
                </div>
                <div
                  style={{
                    border: "1px solid white",
                    padding: "3px 10px",
                    width: "100px",
                  }}
                >
                  {" "}
                  <Typography color="primary">investment (WL)</Typography>
                </div>
                <div
                  style={{
                    border: "1px solid white",
                    padding: "3px 10px",
                    width: "50px",
                  }}
                >
                  {" "}
                  <Typography color="primary">currency</Typography>
                </div>
                <div
                  style={{
                    border: "1px solid white",
                    padding: "3px 10px",
                    width: "130px",
                  }}
                >
                  {" "}
                  <Typography color="primary">KYC_STATUS</Typography>
                </div>
                <div
                  style={{
                    border: "1px solid white",
                    padding: "3px 10px",
                    width: "80px",
                  }}
                >
                  {" "}
                  <Typography color="primary">Invit code</Typography>
                </div>
                <div
                  style={{
                    border: "1px solid white",
                    padding: "3px 10px",
                    width: "100px",
                  }}
                >
                  {" "}
                  <Typography color="primary">Investment</Typography>
                </div>
                <div
                  style={{
                    border: "1px solid white",
                    padding: "3px 10px",
                    width: "50px",
                  }}
                >
                  {" "}
                  <Typography color="primary">currency</Typography>
                </div>
                <div
                  style={{
                    border: "1px solid white",
                    padding: "3px 10px",
                    width: "120px",
                  }}
                >
                  {" "}
                  <Typography color="primary">Investment Validated</Typography>
                </div>
                <div
                  style={{
                    border: "1px solid white",
                    padding: "3px 10px",
                    width: "320px",
                  }}
                >
                  {" "}
                  <Typography color="primary">xtz/eth Address</Typography>
                </div>
              </div>
              {userList &&
              userList.length &&
              filteredUsers &&
              filteredUsers.length ? (
                filteredUsers?.map((user: User, index: number) => {
                  const createdAt = new Date(user.date);
                  const date = createdAt.toLocaleString();

                  return (
                    <div
                      style={{ display: "flex", textAlign: "center" }}
                      key={`user-${index}`}
                    >
                      <div
                        style={{
                          border: "1px solid white",
                          padding: "3px 10px",
                          width: "195px",
                        }}
                      >
                        {" "}
                        <Typography color="secondary">{user.id}</Typography>
                      </div>
                      <div
                        style={{
                          border: "1px solid white",
                          padding: "3px 10px",
                          width: "230px",
                        }}
                      >
                        {" "}
                        <Typography color="secondary">{user.email}</Typography>
                      </div>
                      <div
                        style={{
                          border: "1px solid white",
                          padding: "3px 10px",
                          width: "150px",
                        }}
                      >
                        {" "}
                        <Typography color="secondary">
                          {user.phoneNumber}
                        </Typography>
                      </div>
                      <div
                        style={{
                          border: "1px solid white",
                          padding: "3px 10px",
                          width: "100px",
                        }}
                      >
                        {" "}
                        <Typography color="secondary">{date}</Typography>
                      </div>
                      <div
                        style={{
                          border: "1px solid white",
                          padding: "3px 10px",
                          width: "50px",
                        }}
                      >
                        {" "}
                        <Typography color="secondary">{user.round}</Typography>
                      </div>
                      <div
                        style={{
                          border: "1px solid white",
                          padding: "3px 10px",
                          width: "75px",
                        }}
                      >
                        {" "}
                        <Typography color="secondary">
                          {user.discount ? "true" : "false"}
                        </Typography>
                      </div>
                      <div
                        style={{
                          border: "1px solid white",
                          padding: "3px 10px",
                          width: "100px",
                        }}
                      >
                        {" "}
                        <Typography color="secondary">
                          {user.firstName}
                        </Typography>
                      </div>
                      <div
                        style={{
                          border: "1px solid white",
                          padding: "3px 10px",
                          width: "100px",
                        }}
                      >
                        {" "}
                        <Typography color="secondary">
                          {user.lastName}
                        </Typography>
                      </div>
                      <div
                        style={{
                          border: "1px solid white",
                          padding: "3px 10px",
                          width: "100px",
                        }}
                      >
                        {" "}
                        <Typography color="secondary">
                          {user.investment}
                        </Typography>
                      </div>
                      <div
                        style={{
                          border: "1px solid white",
                          padding: "3px 10px",
                          width: "50px",
                        }}
                      >
                        {" "}
                        <Typography color="secondary">
                          {user.currency}
                        </Typography>
                      </div>

                      <div
                        style={{
                          border: "1px solid white",
                          padding: "3px 10px",
                          width: "130px",
                        }}
                      >
                        {" "}
                        <Typography color="secondary">
                          {user.KYC_STATUS}
                        </Typography>
                      </div>
                      <div
                        style={{
                          border: "1px solid white",
                          padding: "3px 10px",
                          width: "80px",
                        }}
                      >
                        {" "}
                        <Typography color="secondary">
                          {user.invitationCode}
                        </Typography>
                      </div>
                      <div
                        style={{
                          border: "1px solid white",
                          padding: "3px 10px",
                          width: "100px",
                        }}
                      >
                        {" "}
                        <Typography color="secondary">
                          {user?.confirmedInvestment || ""}
                        </Typography>
                      </div>
                      <div
                        style={{
                          border: "1px solid white",
                          padding: "3px 10px",
                          width: "50px",
                        }}
                      >
                        {" "}
                        <Typography color="secondary">
                          {user?.confirmedCurrency || ""}
                        </Typography>
                      </div>
                      <div
                        style={{
                          border: "1px solid white",
                          padding: "3px 10px",
                          width: "120px",
                        }}
                      >
                        {user?.investmentValidated ? (
                          <Typography color="secondary">YES</Typography>
                        ) : user?.confirmedInvestment ? (
                          <Button
                            variant="outlined"
                            onClick={() => handleValidateUserInvest(user.id)}
                          >
                            Validate
                          </Button>
                        ) : null}
                      </div>
                      <div
                        style={{
                          border: "1px solid white",
                          padding: "3px 10px",
                          width: "320px",
                        }}
                      >
                        {" "}
                        <Typography color="secondary">
                          {user?.xtzAddress || ""}
                        </Typography>
                      </div>
                    </div>
                  );
                })
              ) : loading ? (
                <CircularProgress
                  color="primary"
                  style={{ marginTop: "20px", alignSelf: "center" }}
                />
              ) : null}
            </div>
          ) : (
            <Box
              component="form"
              style={{
                marginTop: "25px",
                padding: "30px 40px",
                borderRadius: 10,
                maxWidth: "70%",
              }}
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
                "& .MuiOutlinedInput-root": {
                  borderColor: "white",
                },
              }}
              noValidate
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <TextField
                  id="emailField"
                  color="secondary"
                  inputProps={{ style: inputStyle }}
                  variant="outlined"
                  value={email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setEmail(e.target.value)
                  }
                />
                <TextField
                  id="passwordField"
                  color="secondary"
                  inputProps={{ style: inputStyle }}
                  label="Password"
                  type="password"
                  required
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPassword(e.target.value)
                  }
                  value={password}
                />

                {!loading ? (
                  <Button
                    variant="contained"
                    style={{ marginTop: "10px" }}
                    color="primary"
                    onClick={handleLogin}
                  >
                    Log in
                  </Button>
                ) : (
                  <CircularProgress color="primary" />
                )}
              </div>
            </Box>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
}

async function readWhitelist() {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/readWhitelist`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "GET",
  });
  const { data } = await res.json();
  if (data.nextRound?.round) {
    return data.nextRound;
  }
  if (data.currentRound?.round) {
    return data.currentRound;
  }
  return data.previousRound;
}

async function createRound(roundData: { MLCPrice: number; openingDate: string | undefined; closingDate: string | undefined; discountPercent: string; nbSpots: number; round: number; totalSpots: number; }): Promise<any> {
  const token = localStorage.getItem("token");
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/create-round?token=${token}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(roundData),
      }
    );

    const json = await res.json();
    console.log(json);
    return json
  } catch (error) {
    console.error(error);
  }
}

function CreateRound() {
  // Whitelist round
  const [MLCPrice, setMLCPrice] = useState(0.0455);
  const [openingDate, setOpeningDate] = useState<Dayjs | null>(dayjs());
  const [closingDate, setClosingDate] = useState<Dayjs | null>(dayjs());
  const [discountPercent, setDiscountPercent] = useState("220");
  const [nbSpots, setNbSpots] = useState(2);
  const [round, setRound] = useState(0);
  const [totalSpots, setTotalSpots] = useState(5000);

  const roundData = { MLCPrice, openingDate: openingDate?.toString(), closingDate: closingDate?.toString(), discountPercent, nbSpots, round, totalSpots }

  console.log(JSON.stringify(roundData, null, 2))

  useEffect(() => {
    async function loadWhitelist() {
      const wl = await readWhitelist();
      setMLCPrice(wl.MLCPrice);
      setOpeningDate(dayjs(wl.closingDate).add(1, 'hour'))
      setClosingDate(dayjs(wl.closingDate).add(7, 'days'));
      setDiscountPercent(wl.discountPercent);
      setNbSpots(wl.nbSpots);
      setRound(wl.round + 1);
      setTotalSpots(wl.totalSpots);
      console.log(wl);
    }
    loadWhitelist();
  }, []);

  async function doCreateRound() {
    const res = await createRound(roundData)
    if (res.data) {
      const { data } = res
      console.log(data)
      setMLCPrice(data.MLCPrice);
      setOpeningDate(dayjs(data.closingDate).add(1, 'hours'))
      setClosingDate(dayjs(data.closingDate).add(7, 'days'));
      setDiscountPercent(data.discountPercent);
      setNbSpots(data.nbSpots);
      setRound(data.round + 1);
      setTotalSpots(data.totalSpots);
    }
  }

  return (
    <Box
      component="form"
      style={{
        marginTop: "25px",
        padding: "30px 40px",
        borderRadius: 10,
        maxWidth: "70%",
      }}
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" },
        "& .MuiOutlinedInput-root": {
          borderColor: "white",
        },
      }}
      noValidate
    >
      <h2 style={{color: 'white'}}>Create New Round</h2>
      <TextField
        color="secondary"
        label="MLC Price"
        type="number"
        required
        inputProps={{ inputMode: "numeric" }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMLCPrice(Number(e.target.value))}
        value={MLCPrice}
      />
      <DateTimePicker
        label="Opening Date"
        onChange={(newValue: Dayjs | null) => setOpeningDate(newValue)}
        value={openingDate}
        renderInput={(params: JSX.IntrinsicAttributes) => <TextField {...params} required />}
      />
      <DateTimePicker
        label="Closing Date"
        onChange={(newValue: Dayjs | null) => setClosingDate(newValue)}
        value={closingDate}
        renderInput={(params: JSX.IntrinsicAttributes) => <TextField {...params} required />}
      />
      <TextField
        color="secondary"
        label="Discount %"
        required
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDiscountPercent(e.target.value)}
        value={discountPercent}
      />
      <TextField
        color="secondary"
        label="Number of Spots"
        type="number"
        required
        inputProps={{ inputMode: "numeric" }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNbSpots(Number(e.target.value))}
        value={nbSpots}
      />
      <TextField
        color="secondary"
        label="Round"
        type="number"
        required
        inputProps={{ inputMode: "numeric" }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRound(Number(e.target.value))}
        value={round}
      />
      <TextField
        color="secondary"
        label="Total Spots"
        type="number"
        required
        inputProps={{ inputMode: "numeric" }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTotalSpots(Number(e.target.value))}
        value={totalSpots}
      />
      <Button variant="outlined" onClick={doCreateRound}>
        Create Round
      </Button>
    </Box>
  );
}

export default Adm;
