import React, { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

export const Countdown: React.FC<any> = ({ date }) => {
  const [state, setState] = useState<{ days: number; hours: number; min: number; sec: number }>({ days: 0, hours: 0, min: 0, sec: 0 });
  const desktop = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    const interval: any = setInterval(() => {
      const newdate: any = calculateCountdown(date);
      setState(newdate);
    }, 1000);

    return () => clearInterval(interval);
  }, [date]);

  const calculateCountdown = (endDate: any) => {
    
    let diff: any = (Date.parse(endDate) - Date.now()) / 1000;

    // clear countdown when date is reached
    if (diff <= 0) return false;

    const timeLeft = {
      years: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
      millisec: 0,
    };

    // calculate time difference between now and expected date
    if (diff >= 365.25 * 86400) {
      // 365.25 * 24 * 60 * 60
      timeLeft.years = Math.floor(diff / (365.25 * 86400));
      diff -= timeLeft.years * 365.25 * 86400;
    }
    if (diff >= 86400) {
      // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) {
      // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
      timeLeft.min = Math.floor(diff / 60);
      diff -= timeLeft.min * 60;
    }
    timeLeft.sec = diff;

    return timeLeft;
  };

  const addLeadingZeros = (value: any) => {
    value = String(Math.round(value));
    while (value.length < 2) {
      value = "0" + value;
    }
    return value;
  };

  return (
    <div className='Countdown'>
      <span className='Countdown-col'>
        <span className='Countdown-col-element' style={{margin: desktop? "0 15px": "0 10px"}}>
          <strong style={{ fontSize: desktop ? "36px" : "24px" }}>{addLeadingZeros(state.days)}</strong>
          <span>{state.days === 1 ? "Day" : "Days"}</span>
        </span>
      </span>

      <span className='Countdown-col'>
        <span className='Countdown-col-element' style={{margin: desktop? "0 15px": "0 10px"}}>
          <strong style={{ fontSize: desktop ? "36px" : "24px" }}>{addLeadingZeros(state.hours)}</strong>
          <span>Hours</span>
        </span>
      </span>

      <span className='Countdown-col'>
        <span className='Countdown-col-element' style={{margin: desktop? "0 15px": "0 10px"}}>
          <strong style={{ fontSize: desktop ? "36px" : "24px" }}>{addLeadingZeros(state.min)}</strong>
          <span>Min</span>
        </span>
      </span>

      <span className='Countdown-col'>
        <span className='Countdown-col-element' style={{margin: desktop? "0 15px": "0 10px"}}>
          <strong style={{ fontSize: desktop ? "36px" : "24px" }}>{addLeadingZeros(state.sec)}</strong>
          <span>Sec</span>
        </span>
      </span>
    </div>
  );
};
