import React, { useState, useEffect, useMemo } from "react";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import StarIcon from "@mui/icons-material/Star";
import useMediaQuery from "@mui/material/useMediaQuery";

import SynapsClient from "@synaps-io/verify.js";
import { useLocation } from "react-router-dom";
import { analytics } from "../index";


interface KYCProps {
  goToNextStep?: () => void;
  synapsSessions?: string;
}

export const KYC: React.FC<KYCProps> = ({ goToNextStep, synapsSessions }) => {
  const [kycReady, setKycReady] = useState(false);
  const [kycDone, setKycDone] = useState(false);
  const [synapsLoader, setSynapsLoader] = useState(false);

  const desktop = useMediaQuery("(min-width:600px)");

  const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  };
  let query = useQuery();

  useEffect(() => {
    setSynapsLoader(true);
    const id = query.get("id");

    if (id) {
      startVerification(id);
      analytics?.logEvent("click_KYC_BUTTON", { name: "KYC_button" });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startVerification = async (investorId: string) => {
    try {
      let currentSynapsSession = "";

      if (synapsSessions && synapsSessions.length) {
        currentSynapsSession = synapsSessions;
      } else {
        const token = localStorage.getItem("token");
        const getSessionIDRequest = await fetch(`${process.env.REACT_APP_API_URL}/synapsVerification?investorId=${investorId}&token=${token}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const SessionIDJSON = await getSessionIDRequest.json();

        currentSynapsSession = SessionIDJSON?.data?.session_id;
      }

      const Synaps = new SynapsClient(currentSynapsSession, "workflow");
      Synaps.init();
      setTimeout(() => {
        setKycReady(true);
        setSynapsLoader(false);
      }, 2000);
      Synaps.on("finish", () => {
        setKycDone(true);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: "50px",
      }}
    >
      {!kycDone ? (
        <Box
          sx={{
            background: "#6b52db26",
            padding: desktop ? "10px 40px" : "10px",
            borderRadius: 10,
            maxWidth: desktop ? "70%" : "95%",
            textAlign: "center",
            fontFamily: "Nunito",
          }}
        >
          <Typography variant='h5' style={{ marginTop: "20px", fontFamily: "Nunito" }}>
            Important information before starting your KYC process
          </Typography>
          <List sx={{ width: "100%", color: "white" }} color='primary'>
            <ListItem>
              <ListItemIcon>
                <StarIcon sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontFamily: "Nunito", fontSize: desktop ? "1rem" : "0.85rem" }}
                color='primary'
                primary=' Plan 5 minutes to complete the KYC process (3 steps will require to be done in succession) the first step “facial liveliness recognition” may require multiple attempts'
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <StarIcon sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontFamily: "Nunito", fontSize: desktop ? "1rem" : "0.85rem" }}
                color='primary'
                primary='You will need 1 ID document among : Passport OR National ID Card'
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <StarIcon sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontFamily: "Nunito", fontSize: desktop ? "1rem" : "0.85rem" }}
                color='primary'
                primary='You will need a file (image or pdf) of a Residency Proof Document. Accepted documents are : elecricity bill, Water Bill, Gas bill, Landline bill or Bank statement'
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <StarIcon sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontFamily: "Nunito", fontSize: desktop ? "1rem" : "0.85rem" }}
                color='primary'
                primary='To be valid, the residency proof document you upload must show your current residency address and be less than 3 months old'
              />
            </ListItem>
          </List>
          <Typography variant='h6' color='primary' style={{ marginTop: "10px", marginBottom: "10px", fontFamily: "Nunito" }}>
            You can now start your KYC or come back when you have those documents ready :
          </Typography>
        </Box>
      ) : null}

      {!synapsLoader ? null : <CircularProgress color='secondary' style={{ marginTop: "40px", marginBottom: "40px" }} />}
      {kycReady && !kycDone ? (
        <Button
          variant='contained'
          size='large'
          style={{ marginTop: "40px", marginBottom: "40px", fontSize: "20px" }}
          id='synaps-btn'
          color='primary'
        >
          Complete KYC
        </Button>
      ) : null}

      {kycDone ? (
        <Typography
          color='primary'
          style={{ maxWidth: "75%", textAlign: "center", fontFamily: "Nunito", fontSize: desktop ? "36px" : "24px", lineHeight: "34px" }}
        >
          Thank you! If you have completed all the steps your KYC will soon be reviewed
        </Typography>
      ) : null}
    </div>
  );
};
