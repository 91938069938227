import React, { useState, useMemo } from "react";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";

import { KYC } from "./KYC";

import { useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

interface KYCPopUpProps {
  synapsSessions?: string;
  fetchInvestorInfo: (id: string) => Promise<void>;
}

export const KYCPopUp: React.FC<KYCPopUpProps> = ({ synapsSessions, fetchInvestorInfo }) => {
  const [opened, setOpened] = useState(false);

  const openMenu = () => {
    setOpened(true);
  };

  const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  };
  let query = useQuery();

  const closeMenu = () => {
    const id = query.get("id");

    if (id) {
      try {
        fetchInvestorInfo(id);
      } catch (error) {
        console.log(error);
      }
    }
    setOpened(false);
  };

  //const [residency, setResidency] = useState<string | null | undefined>("");
  //const [identity, setIdentity] = useState<string | null | undefined>("");
  //const [liveness, setLiveness] = useState<string | null | undefined>("");

  const desktop = useMediaQuery("(min-width:600px)");

  return (
    <>
      <Button
        variant='contained'
        color='info'
        style={{
          padding: "12px",
          width: desktop ? "auto" : "75%",
          borderRadius: "12px",
          color: "white",
          fontSize: desktop ? "24px" : "16px",
          fontFamily: "Alphakind",
          textTransform: "none",
          marginTop: "30px",
          marginBottom: "15px",
          border: "2px solid white",
          boxShadow: "0px 0px 10px #ffffff",
        }}
        onClick={() => openMenu()}
      >
        Do your KYC
      </Button>
      <Dialog
        open={opened}
        PaperProps={{
          sx: {
            background: "linear-gradient(180deg, #0F0E1C 0%, #1C0F6B 100%)",
            color: "white",
            height: "100%",
            width: "100%",
            padding: "20px",
            maxWidth: "100%",
            marginRight: desktop ? "auto" : "5px",
            marginLeft: desktop ? "auto" : "5px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "10px",
            position: "relative",
          },
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <Typography
            style={{
              color: "#FFFFFF",
              fontFamily: "Alphakind",
              fontWeight: 400,
              fontSize: desktop ? "48px" : "32px",
              lineHeight: desktop ? "46px" : "30px",
              textAlign: "center",
              width: "75%",
            }}
          >
            KYC
          </Typography>
        </div>

        <KYC synapsSessions={synapsSessions} />

        <Button
          component='button'
          size='small'
          variant='outlined'
          onClick={closeMenu}
          sx={{ position: "fixed", top: "20px", right: "20px", color: "white", borderColor: "white" }}
        >
          x
        </Button>

        <Button component='button' size='large' variant='contained' color='primary' onClick={closeMenu}>
          Close
        </Button>
      </Dialog>
    </>
  );
};
