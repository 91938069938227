import StarIcon from "@mui/icons-material/Star";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";

import { Countdown } from "./Countdown";

interface RoundInvestProps {
  title: string;
  opened?: boolean;
  investment: number;
  maxInvestment: number;
  round: number;
  openingDate: string;
  closingDate: string;
  roundStatus: string;
  KYC_STATUS: string;
  minAmount: number;
  maxAmount: number;
  investmentConfirmed: boolean;
  MLCPrice: number;
  discountPercent: string;
  whitelistSpots: number;

  handleOpenInvestMenu: (round?: number, close?: boolean) => void;
}

export const RoundInvest: React.FC<RoundInvestProps> = ({
  investment,
  maxInvestment,
  round,
  openingDate,
  closingDate,
  roundStatus,
  MLCPrice,
  discountPercent,
}) => {
  const desktop = useMediaQuery("(min-width:600px)");

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: desktop ? "100px" : "75px",
        marginBottom: "75px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "start", justifyContent: "center", width: "100%", opacity: roundStatus !== "OPENED" ? 0.5 : 1 }}>
        <div className='loadbar' style={{ marginLeft: desktop ? "30%" : "15%" }}>
          {/* <div className={roundStatus !== "OPENED" && roundStatus !== "closed" ? "circle-closed" : "circle"} /> */}
          <div className={"circle" } />
          <div className={"circle"} style={{ top: "100%" }} />
          <strong className={"bar"} style={{ height:roundStatus === "OPENED"? `${(100 * maxInvestment) / maxInvestment}%`: `${(100 * investment) / maxInvestment}%` }}></strong>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "75px",
            alignItems: "start",
            width: desktop ? "50%" : "60%",

            transform: "translateY(-20px)",
          }}
        >
          <Typography style={{ color: "white", fontSize: desktop ? "32px" : "24px", fontFamily: "Alphakind" }}>
            {`Current round`} {roundStatus !== "OPENED" ? <span style={{ fontSize: desktop ? "24px" : "18px" }}>{`(${roundStatus})`}</span> : ""}
            {roundStatus === "OPENED" ? <span style={{ fontSize: desktop ? "24px" : "18px" }}>{`is Open!`}</span> : ""}
          </Typography>

          {closingDate && roundStatus === "OPENED" ? (
            <Typography
              style={{
                color: "white",
                fontSize: desktop ? "24px" : "18px",
                fontFamily: "Nunito",
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
                fontWeight: "bold",
              }}
            >
              Closes on:
            </Typography>
          ) : null}
          {closingDate && roundStatus === "OPENED" ? (
            <Typography
              style={{
                color: "white",
                fontSize: desktop ? "24px" : "18px",
                fontFamily: "Nunito",
                display: "flex",
                alignItems: "center",

                textAlign: "start",
              }}
            >
              {Intl.DateTimeFormat("en-US", { dateStyle: "full", timeStyle: "short" }).format(new Date(closingDate))} Paris Time
            </Typography>
          ) : null}
          {closingDate && roundStatus === "OPENED" ? (
            <Typography
              style={{
                color: "white",
                fontSize: desktop ? "24px" : "18px",
                fontFamily: "Nunito",
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
                fontWeight: "bold",
              }}
            >
              Time left before closing:
            </Typography>
          ) : null}
          {closingDate && roundStatus === "OPENED" ? (
            <Box sx={{ color: "white", transform: desktop ? "translateX(-15px)" : "translateX(-10px)" }}>
              <Countdown date={new Date(closingDate)} />
            </Box>
          ) : null}
          {openingDate && roundStatus === "not opened" ? (
            <Typography
              style={{
                color: "white",
                fontSize: desktop ? "24px" : "18px",
                fontFamily: "Nunito",
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
                fontWeight: "bold",
              }}
            >
              Starts on:
            </Typography>
          ) : null}
          {openingDate && roundStatus === "not opened" ? (
            <Typography
              style={{
                color: "white",
                fontSize: desktop ? "24px" : "18px",
                fontFamily: "Nunito",
                display: "flex",
                alignItems: "center",
                // marginTop: "10px",
                textAlign: "start",
              }}
            >
              {Intl.DateTimeFormat("en-US", { dateStyle: "full", timeStyle: "short" }).format(new Date(openingDate))} Paris Time
            </Typography>
          ) : null}
          {openingDate && roundStatus === "not opened" ? (
            <Typography
              style={{
                color: "white",
                fontSize: desktop ? "24px" : "18px",
                fontFamily: "Nunito",
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
                fontWeight: "bold",
              }}
            >
              Opens in:
            </Typography>
          ) : null}
          {openingDate && roundStatus === "not opened" ? (
            <Box sx={{ color: "white", transform: desktop ? "translateX(-15px)" : "translateX(-10px)" }}>
              <Countdown date={new Date(openingDate)} />
            </Box>
          ) : null}

          <List sx={{ width: "100%", color: "white", fontFamily: "Nunito" }} color='primary'>
            <ListItem sx={{ paddingLeft: 0 }}>
              <ListItemIcon>
                <StarIcon sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontFamily: "Nunito", fontSize: desktop ? "1rem" : "0.85rem" }}
                color='primary'
                primary={`${discountPercent}% discount on public price`}
              />
            </ListItem>
            <ListItem sx={{ paddingLeft: 0 }}>
              <ListItemIcon>
                <StarIcon sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontFamily: "Nunito", fontSize: desktop ? "1rem" : "0.85rem" }}
                color='primary'
                primary='1 000€ min investment'
              />
            </ListItem>
            <ListItem sx={{ paddingLeft: 0 }}>
              <ListItemIcon>
                <StarIcon sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontFamily: "Nunito", fontSize: desktop ? "1rem" : "0.85rem" }}
                color='primary'
                primary={`Price of $MLC : ${MLCPrice}€`}
              />
            </ListItem>
          </List>
        </div>
      </Box>
    </div>
  );
};
