import React, { useEffect, useState, useMemo } from "react";

import logo from "./assets/images/Page1_Logo.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as jose from "jose";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { RoundInvest } from "./components/RoundInvest";

import { InvestmentPopup } from "./components/InvestmentPopup";

import { InvestmentLogin } from "./components/InvestmentLogin";
import { useLocation } from "react-router-dom";
import { KYCPopUp } from "./components/KYCPopUp";
import edward from "./assets/images/edward.png";
import { analytics } from "./index";
//@ts-ignore
import RIB_MLP from "./assets/docs/RIB_MLP.pdf";
import "./App.css";

type CryptoSymbol = "xtz" | "eth" | "matic"

function isCrypto(currency: string): currency is CryptoSymbol {
  return currency === "eth" || currency === "xtz" || currency === "matic"
}

const publicKeys = {
  eth: "0x0d24e5b142cdBb0f9F9c5B317e4177506d6200ac",
  matic: "0x0d24e5b142cdBb0f9F9c5B317e4177506d6200ac",
  xtz: "0x0d24e5b142cdBb0f9F9c5B317e4177506d6200ac"
}

export const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#82C44C",
    },
    secondary: {
      main: "#FFFFFF",
    },
    info: {
      main: "#469108",
    },
  },
});

function InvestPage(props: any) {
  const desktop = useMediaQuery("(min-width:600px)");

  const [currentInvestment, setCurrentInvestment] = useState(0);
  const [maxInvestment, setMaxInvestment] = useState(0);
  const [openingDate, setOpeningDate] = useState("");
  const [closingDate, setClosingDate] = useState("");
  const [logged, setLogged] = useState(false);
  const [email, setEmail] = useState("");
  const [roundStatus, setRoundStatus] = useState("not opened");
  const [investment, setInvestment] = useState(0);
  const [currency, setCurrency] = useState("");
  const [invitationCode, setInvitationCode] = useState("");
  const [xtzAddress, setXtzAddress] = useState("");
  const [investmentConfirmed, setInvestmentConfirmed] = useState(false);
  const [investmentValidated, setInvestmentValidated] = useState(false);
  const [confirmedCurrency, setConfirmedCurrency] = useState("");
  const [confirmedInvestment, setConfirmedInvestment] = useState(0);
  const [rate, setRate] = useState(0);
  const [KYC_STATUS, setKYC_STATUS] = useState("NOT_SUBMITTED");
  const [maxAmount, setMaxAmount] = useState(0);
  const [minAmount, setMinAmount] = useState(0);
  const [round, setRound] = useState(0);
  const [discountPercent, setDiscountPercent] = useState("0");
  const [synapsSessions, setSynapsSessions] = useState("");
  const [MLCPrice, setMLCPrice] = useState(0);
  const [whitelistSpots, setWhitelistSpots] = useState(0);
  const [rates, setRates] = useState<{ MLPUSD: number; MLPEUR: number; MLPXTZ: number, MLCETH: number, MLCMATIC: number }>();

  const fetchInvestorInfo = async (id: string) => {
    try {
      const investorInfo = await fetch(`${process.env.REACT_APP_API_URL}/investorInfo?id=${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const investorInfoResponse = await investorInfo.json();

      const {
        email,
        round,

        investment = 0,
        currency = "",
        userInvitationCode = "",
        xtzAddress = "",
        investmentConfirmed = false,
        investmentValidated = false,
        confirmedCurrency = "",
        confirmedInvestment = 0,
        KYC_STATUS = "NOT_SUBMITTED",
        synapsSessions = "",
      } = investorInfoResponse.data;

      setInvestment(investment);
      setCurrency(currency);
      setInvitationCode(userInvitationCode);
      setXtzAddress(xtzAddress);
      setInvestmentConfirmed(investmentConfirmed);
      setInvestmentValidated(investmentValidated);
      setConfirmedCurrency(confirmedCurrency);
      setConfirmedInvestment(confirmedInvestment);
      setKYC_STATUS(KYC_STATUS);
      setEmail(email);
      setRound(round);
      setSynapsSessions(synapsSessions);

      if (rates) {
        switch(currency) {
          case 'eth': setRate(rates.MLCETH);
            break;
          case 'matic': setRate(rates.MLCMATIC);
            break;
          case 'xtz': setRate(rates.MLPXTZ);
            break;
          case "€": setRate(rates.MLPEUR);
            break;
          default:
            setRate(rates.MLPUSD);
        }
        console.log('rates', rates, currency)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  };
  let query = useQuery();

  useEffect(() => {
    if (round) {
      getInvestmentRound();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [round]);

  useEffect(() => {
    const id = query.get("id");
    const loggedin = query.get("logged") || "";
    const token = localStorage.getItem("token");
    let decodedToken: any = {};
    if (token) {
      decodedToken = jose.decodeJwt(token);
    }

    const { id: tokenId = "" } = decodedToken;
    if (loggedin === "true" && token && tokenId === id) {
      setLogged(true);
    }
    if (id) {
      fetchInvestorInfo(id);
      const timer = setInterval(() => {
        fetchInvestorInfo(id);
      }, 60 * 1000);

      return () => {
        clearInterval(timer);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInvestmentRound = async () => {
    try {
      const getInvestmentRoundRequest = await fetch(`${process.env.REACT_APP_API_URL}/investmentRound?round=${round}`, {});
      const getInvestmentRoundJSON = await getInvestmentRoundRequest.json();

      setCurrentInvestment(getInvestmentRoundJSON.data.round.currentInvestment);
      setMaxInvestment(getInvestmentRoundJSON.data.round.maxInvestment);
      setOpeningDate(getInvestmentRoundJSON.data.round.openingDate);
      setClosingDate(getInvestmentRoundJSON.data.round.closingDate);
      setMinAmount(getInvestmentRoundJSON.data.round.minAmount);
      setMaxAmount(getInvestmentRoundJSON.data.round.maxAmount);

      const { MLPEUR, MLPUSD, MLPXTZ, MLCETH, MLCMATIC, discountPercent = "0", MLCPrice = 0, whitelistSpots = 0 } = getInvestmentRoundJSON.data.round;
      setRates({ MLPEUR, MLPUSD, MLPXTZ, MLCETH, MLCMATIC });

      setDiscountPercent(discountPercent);
      setMLCPrice(MLCPrice);
      setWhitelistSpots(whitelistSpots);

      const now = new Date()
      const openingDate = new Date(getInvestmentRoundJSON.data.round.openingDate)
      const closingDate = new Date(getInvestmentRoundJSON.data.round.closingDate)
      const currentInvestment = getInvestmentRoundJSON.data.round.currentInvestment ?? 0
      const maxInvestment = getInvestmentRoundJSON.data.round.maxInvestment

      if (now < openingDate) {
        setRoundStatus("not opened");
        return;
      }
      if ( now < closingDate && currentInvestment < maxInvestment) {
        setRoundStatus("OPENED");
        return;
      }

      if (now > closingDate || currentInvestment >= maxInvestment) {
        setRoundStatus("closed");
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleLog = (status: boolean) => {
    setLogged(status);
  };

  const [openInvestMenu, setOpenInvestMenu] = useState<{ open: boolean; round: number | null }>({
    open: false,
    round: null,
  });

  const handleOpenInvestMenu = (round?: number, close?: boolean) => {
    if (round) {
      setOpenInvestMenu({ open: true, round: round });
      analytics?.logEvent("click_INVESTMENT_BUTTON", { name: "investment_button" });
    }
    if (close) {
      setOpenInvestMenu({ open: false, round: null });

      const id = query.get("id");
      if (id) {
        fetchInvestorInfo(id);
      }
    }
  };

  useEffect(() => {
    if (rates) {
      switch(currency) {
        case 'eth': setRate(rates.MLCETH);
          break;
        case 'matic': setRate(rates.MLCMATIC);
          break;
        case 'xtz': setRate(rates.MLPUSD);
          break;
        case "€": setRate(rates.MLPEUR);
          break;
        default:
          setRate(rates.MLPUSD);
      }
      console.log('current rates', rates, currency)
    }
  }, [currency, rates]);

  console.log(confirmedInvestment, rate)

  return (
    <ThemeProvider theme={theme}>
      <div className='App'>
        {logged ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              background: "linear-gradient(180deg, #0F0E1C 0%, #1C0F6B 100%)",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
                position: "fixed",
                top: "0",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                padding: "10px 40px",
                zIndex: 10,
                background: "#0f0e1c87",
              }}
            >
              <Button
                style={{
                  fontFamily: "Nunito",
                  color: "#82C44C",
                  fontSize: desktop ? "16px" : "14px",
                  letterSpacing: "2%",
                  margin: "0 50px 0 25px",
                  borderRadius: "12px",
                  textTransform: "none",
                  padding: "5px 10px",
                }}
                variant='outlined'
                color='primary'
                size='small'
                onClick={() => {}}
              >
                {email}
              </Button>
            </div>
            <div style={{ width: "100%" }}>
              <img width={desktop ? "20%" : "50%"} height='auto' src={logo} alt='logo' loading='lazy' style={{ marginTop: "95px" }}></img>
            </div>
            {KYC_STATUS === "REJECTED" ? (
              <>
                <Typography
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Alphakind",
                    fontWeight: 400,
                    fontSize: desktop ? "24px" : "18px",
                    lineHeight: desktop ? "26px" : "20px",
                    textAlign: "center",
                    width: desktop ? "50%" : "75%",
                    marginBottom: "5px",
                    marginTop: "60px",
                  }}
                >
                  There was an error with your KYC
                </Typography>
                <Typography
                  style={{
                    color: "#82C44C",
                    fontFamily: "Nunito",
                    fontWeight: 700,
                    fontSize: desktop ? "20px" : "14px",
                    lineHeight: desktop ? "22px" : "16px",
                    textAlign: "center",
                    marginTop: "15px",
                    marginBottom: "20px",
                    width: desktop ? "50%" : "75%",
                  }}
                >
                  Please submit your KYC again. Make sure that every document sent is clear and not too dark or blurry. If your KYC is rejected a
                  second time, please write us a message in our chat or at support@getimagine.org
                </Typography>

                <KYCPopUp synapsSessions={synapsSessions} fetchInvestorInfo={fetchInvestorInfo} />
              </>
            ) : null}
            {KYC_STATUS === "NOT_SUBMITTED" ? (
              <>
                <Typography
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Alphakind",
                    fontWeight: 400,
                    fontSize: desktop ? "32px" : "24px",
                    lineHeight: desktop ? "34px" : "26px",
                    textAlign: "center",
                    width: desktop ? "50%" : "75%",
                    marginBottom: "30px",
                    marginTop: "60px",
                  }}
                >
                  You have to complete your KYC to be able to invest in the private sale
                </Typography>

                <KYCPopUp synapsSessions={synapsSessions} fetchInvestorInfo={fetchInvestorInfo} />
              </>
            ) : null}
            {KYC_STATUS === "SUBMITTED" ? (
              <>
                <Typography
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Alphakind",
                    fontWeight: 400,
                    fontSize: desktop ? "32px" : "24px",
                    lineHeight: desktop ? "34px" : "26px",
                    textAlign: "center",
                    width: desktop ? "50%" : "75%",
                    marginTop: "60px",
                  }}
                >
                  Your KYC is currently being reviewed
                </Typography>
              </>
            ) : null}
            {KYC_STATUS === "VALIDATED" && !investmentConfirmed ? (
              <>
                <Typography
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Alphakind",
                    fontWeight: 400,
                    fontSize: desktop ? "32px" : "24px",
                    lineHeight: desktop ? "34px" : "26px",
                    textAlign: "center",
                    marginTop: "60px",
                    width: desktop ? "50%" : "75%",
                  }}
                >
                  Earn 10% free My Lovely Coin
                </Typography>
                <Typography
                  style={{
                    color: "#82C44C",
                    fontFamily: "Nunito",
                    fontWeight: 700,
                    fontSize: desktop ? "24px" : "16px",
                    lineHeight: desktop ? "26px" : "18px",
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  {`Your invitation code: ${invitationCode}`}
                </Typography>

                <a style={{ textDecoration: "none" }} href='https://youtu.be/V0SJaBjxAV4' target='_blank' rel='noreferrer'>
                  <Button
                    variant='contained'
                    color='info'
                    style={{
                      padding: "12px",
                      width: desktop ? "auto" : "75%",
                      borderRadius: "12px",
                      color: "white",
                      fontSize: desktop ? "18px" : "16px",
                      fontFamily: "Alphakind",
                      textTransform: "none",
                      marginBottom: desktop ? "75px" : "40px",
                      marginTop: "20px",
                    }}
                  >
                    See the explanation video
                  </Button>
                </a>

                <Typography
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Alphakind",
                    fontWeight: 400,
                    fontSize: desktop ? "32px" : "24px",
                    lineHeight: desktop ? "34px" : "26px",
                    textAlign: "center",
                    width: desktop ? "50%" : "75%",
                  }}
                >
                  Your KYC is completed
                </Typography>

                <Typography
                  style={{
                    color: "#82C44C",
                    fontFamily: "Nunito",
                    fontWeight: 700,
                    fontSize: desktop ? "24px" : "16px",
                    lineHeight: desktop ? "26px" : "18px",
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  You are now ready to invest
                </Typography>
              </>
            ) : null}
            {investmentConfirmed && !investmentValidated ? (
              <>
                <Typography
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Alphakind",
                    fontWeight: 400,
                    fontSize: desktop ? "32px" : "24px",
                    lineHeight: desktop ? "34px" : "26px",
                    textAlign: "center",
                    width: desktop ? "50%" : "75%",
                    marginTop: desktop ? "75px" : "40px",
                  }}
                >
                  {`You have invested ${new Intl.NumberFormat("fr-FR", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  }).format(confirmedInvestment)}`} {confirmedCurrency === 'xtz' ? 'usdt/c' : confirmedCurrency}
                </Typography>

                <Typography
                  style={{
                    color: "#82C44C",
                    fontFamily: "Nunito",
                    fontWeight: 700,
                    fontSize: desktop ? "24px" : "16px",
                    lineHeight: desktop ? "26px" : "18px",
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  We are currently waiting to receive your investment
                </Typography>
                <Typography
                  style={{
                    color: "white",
                    fontFamily: "Nunito",
                    fontWeight: 700,
                    fontSize: desktop ? "18px" : "14px",
                    lineHeight: desktop ? "20px" : "16px",
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >{isCrypto(confirmedCurrency) ? (
                  `Did you do the transfer ? Our public key for transfer is ${publicKeys[confirmedCurrency]}`
                ) : (
                  <>
                    {"Did you do the bank transfer ? "}
                    <a target='_blank' href={RIB_MLP} rel='noreferrer' style={{ color: "white" }}>
                      Download our RIB Here
                    </a>{" "}
                    to complete your investment.
                  </>
                )}
                </Typography>
              </>
            ) : null}
            {investmentValidated && confirmedCurrency.length ? (
              <>
                <Typography
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Alphakind",
                    fontWeight: 400,
                    fontSize: desktop ? "32px" : "24px",
                    lineHeight: desktop ? "34px" : "26px",
                    textAlign: "center",
                    width: desktop ? "50%" : "75%",
                    marginTop: desktop ? "75px" : "40px",
                  }}
                >
                  {`You have invested ${confirmedInvestment}`} {confirmedCurrency === 'xtz' ? 'usdt/c' : confirmedCurrency}
                </Typography>

                <Typography
                  style={{
                    color: "#82C44C",
                    fontFamily: "Nunito",
                    fontWeight: 700,
                    fontSize: desktop ? "24px" : "16px",
                    lineHeight: desktop ? "26px" : "18px",
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  Your investment has been approved
                </Typography>

                <Typography
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Alphakind",
                    fontWeight: 400,
                    fontSize: desktop ? "32px" : "24px",
                    lineHeight: desktop ? "34px" : "26px",
                    textAlign: "center",
                    width: desktop ? "50%" : "75%",
                    marginTop: desktop ? "50px" : "25px",
                  }}
                >
                  {`You will receive ${new Intl.NumberFormat("fr-FR", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(confirmedInvestment * rate)} MLC (My Lovely Coin) Tokens after the listing, planned for summer 2023`}
                </Typography>
                <Typography
                  style={{
                    color: "#82C44C",
                    fontFamily: "Nunito",
                    fontWeight: 700,
                    fontSize: desktop ? "24px" : "16px",
                    lineHeight: desktop ? "26px" : "18px",
                    textAlign: "center",
                    marginTop: "15px",
                    width: desktop ? "50%" : "75%",
                  }}
                >
                  Additional MLC Tokens may be added to your final claim depending on your invitation codes and on investments effectively realized by your "Referred" friends.
                </Typography>
                <Typography
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Alphakind",
                    fontWeight: 400,
                    fontSize: desktop ? "32px" : "24px",
                    lineHeight: desktop ? "34px" : "26px",
                    textAlign: "center",
                    width: desktop ? "50%" : "75%",
                    marginTop: desktop ? "50px" : "25px",
                  }}
                >
                  {`Don't hesitate to share your Referral code if you didn't do it yet:`}
                </Typography>
                <Typography
                  style={{
                    color: "#82C44C",
                    fontFamily: "Nunito",
                    fontWeight: 700,
                    fontSize: desktop ? "24px" : "16px",
                    lineHeight: desktop ? "26px" : "18px",
                    textAlign: "center",
                    marginTop: "15px",
                    width: desktop ? "50%" : "75%",
                  }}
                >
                  {invitationCode}
                </Typography>
              </>
            ) : null}

            {investmentConfirmed || investmentValidated || roundStatus !== "OPENED" ? null : (
              <Button
                variant='contained'
                color='info'
                style={{
                  padding: "12px",
                  width: desktop ? "auto" : "75%",
                  borderRadius: "12px",
                  color: "white",
                  fontSize: desktop ? "24px" : "16px",
                  fontFamily: "Alphakind",
                  textTransform: "none",
                  marginTop: desktop ? "100px" : "75px",
                  marginBottom: "15px",
                  border: "2px solid white",
                  boxShadow: "0px 0px 10px #ffffff",
                }}
                disabled={investmentConfirmed || investmentValidated || KYC_STATUS !== "VALIDATED" || roundStatus !== "OPENED"}
                onClick={() => handleOpenInvestMenu(round)}
              >
                {`Invest in the Current round`}
              </Button>
            )}
            <Typography
              style={{
                color: "#FFFFFF",
                fontFamily: "Alphakind",
                fontWeight: 400,
                fontSize: desktop ? "32px" : "24px",
                lineHeight: desktop ? "34px" : "26px",
                textAlign: "center",
                width: desktop ? "50%" : "75%",
                marginTop: desktop ? "100px" : "75px",
              }}
            >
              {`Initial investment pledged:`}
            </Typography>
            <Typography
              style={{
                color: "#82C44C",
                fontFamily: "Nunito",
                fontWeight: 700,
                fontSize: desktop ? "32px" : "24px",
                lineHeight: desktop ? "34px" : "26px",
                textAlign: "center",
                marginTop: "15px",
                width: desktop ? "50%" : "75%",
              }}
            >
              {investment} {currency === 'xtz' ? 'usdt / usdc' : currency}
            </Typography>
            {/* <Typography
              style={{
                color: "#FFFFFF",
                fontFamily: "Alphakind",
                fontWeight: 400,
                fontSize: desktop ? "32px" : "24px",
                lineHeight: desktop ? "34px" : "26px",
                textAlign: "center",
                width: desktop ? "50%" : "75%",
                marginTop: desktop ? "100px" : "75px",
              }}
            >
              {roundStatus === "not opened" ? "Register for our launch keynote" : "Watch launch Youtube Live replay"}
            </Typography> */}

            {/* <Typography
              style={{
                color: "#82C44C",
                fontFamily: "Nunito",
                fontWeight: 700,
                fontSize: desktop ? "24px" : "16px",
                lineHeight: desktop ? "26px" : "18px",
                textAlign: "center",
                marginTop: "15px",
                width: desktop ? "50%" : "75%",
              }}
            >
              We explain everything and answer all your question
            </Typography> */}
            {/* <a
              style={{ textDecoration: "none", width: desktop ? "auto" : "75%" }}
              href={keynoteLink ? keynoteLink : "https://www.addevent.com/event/mr13715111"}
              target='_blank'
              rel='noreferrer'
            >
              <Button
                variant='contained'
                color='info'
                style={{
                  padding: "12px",

                  borderRadius: "12px",
                  color: "white",
                  fontSize: desktop ? "18px" : "16px",
                  fontFamily: "Alphakind",
                  textTransform: "none",
                  margin: "15px 0",
                }}
              >
                {roundStatus === "not opened" ? "Add to calendar" : "Obtain private link"}
              </Button>
            </a> */}
            <RoundInvest
              round={round}
              title='Round'
              opened={true}
              roundStatus={roundStatus}
              closingDate={closingDate}
              openingDate={openingDate}
              investment={currentInvestment}
              maxInvestment={maxInvestment}
              handleOpenInvestMenu={handleOpenInvestMenu}
              KYC_STATUS={KYC_STATUS}
              investmentConfirmed={investmentConfirmed}
              minAmount={minAmount}
              maxAmount={maxAmount}
              MLCPrice={MLCPrice}
              discountPercent={discountPercent}
              whitelistSpots={whitelistSpots}
            />
            <InvestmentPopup
              openInvestMenu={openInvestMenu}
              handleOpenInvestMenu={handleOpenInvestMenu}
              getInvestmentRound={getInvestmentRound}
              xtzAddress={xtzAddress}
              rates={rates}
              MLCPrice={MLCPrice}
            />
            <img src={edward} alt='Edward' style={{ width: desktop ? "40%" : "80%", marginBottom: "30px" }} />
            <div
              style={{
                width: "100%",

                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "start",
                padding: "20px 0",
                minHeight: "100px",
                background: "#0f0e1c31",
              }}
            >
              <div style={{ display: "flex", justifyContent: "start", width: "100%" }}>
                <a
                  href='https://www.mylovelyplanet.org/privacy-policy'
                  target='_blank'
                  rel='noreferrer'
                  style={{
                    color: "white",
                    margin: desktop ? "0 20px " : "0 7px",
                    fontSize: desktop ? "16px" : "12px",
                    textDecoration: "none",
                    fontFamily: "Nunito",
                  }}
                >
                  Privacy Policy
                </a>
                <a
                  href='https://www.mylovelyplanet.org/private-sale-terms-and-conditions'
                  target='_blank'
                  rel='noreferrer'
                  style={{
                    color: "white",
                    margin: desktop ? "0 20px " : "0 7px",
                    fontSize: desktop ? "16px" : "12px",
                    textDecoration: "none",
                    fontFamily: "Nunito",
                  }}
                >
                  {`Terms & conditions`}
                </a>
                <a
                  href='https://whitepaper.mylovelyplanet.org/'
                  target='_blank'
                  rel='noreferrer'
                  style={{
                    color: "white",
                    margin: desktop ? "0 20px " : "0 7px",
                    fontSize: desktop ? "16px" : "12px",
                    textDecoration: "none",
                    fontFamily: "Nunito",
                  }}
                >
                  Whitepaper
                </a>
                <a
                  href='https://mylovelyplanet.org/'
                  target='_blank'
                  rel='noreferrer'
                  style={{
                    color: "white",
                    margin: desktop ? "0 20px " : "0 7px",
                    fontSize: desktop ? "16px" : "12px",
                    textDecoration: "none",
                    fontFamily: "Nunito",
                  }}
                >
                  mylovelyplanet.org
                </a>
              </div>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "end", marginTop: "15px", width: "100%" }}>
                <Typography style={{ fontFamily: "Nunito", color: "#ffffffb3", marginRight: "35px" }}>IMAGINE</Typography>
                <Typography style={{ fontFamily: "Nunito", color: "#ffffffb3", marginRight: "35px" }}>Maison de la Technopole</Typography>
                <Typography style={{ fontFamily: "Nunito", color: "#ffffffb3", marginRight: "35px" }}>6 RUE LÉONARD DE VINCI</Typography>
                <Typography style={{ fontFamily: "Nunito", color: "#ffffffb3", marginRight: "35px" }}>CS 20119</Typography>
                <Typography style={{ fontFamily: "Nunito", color: "#ffffffb3", marginRight: "35px" }}>53001 LAVAL CEDEX</Typography>
                <Typography style={{ fontFamily: "Nunito", color: "#ffffffb3", marginRight: "35px" }}>SIREN: 893691758</Typography>
              </div>
            </div>
          </div>
        ) : (
          <InvestmentLogin email={email} handleLog={handleLog} />
        )}
      </div>
    </ThemeProvider>
  );
}

export default InvestPage;
