import React, { useEffect, useState, useMemo } from "react";
import "./App.css";

import logo from "./assets/images/Page1_Logo.png";
import coin from "./assets/images/Coin_face.png";

import image4 from "./assets/images/image4.png";

import bpi from "./assets/images/bpi.png";
import banquepostale from "./assets/images/banquepostale.png";
import kkbb from "./assets/images/kkbb.png";
import ubisoft from "./assets/images/ubisoft.png";

import region from "./assets/images/region.png";
import technopole from "./assets/images/technopole.png";
import unity from "./assets/images/unity.png";
import Icone_Unity from "./assets/gifs/Icone_Unity.gif";

import eden from "./assets/images/eden.png";
import plasticbank from "./assets/images/plasticbank.png";
import spa from "./assets/images/spa.png";
import toitpourlesabeilles from "./assets/images/toitpourlesabeilles.png";

import t1 from "./assets/images/t1.png";
import t2 from "./assets/images/t2.png";
import t3 from "./assets/images/t3.png";
import t4 from "./assets/images/t4.png";
import t5 from "./assets/images/t5.png";
import t6 from "./assets/images/t6.png";
import t7 from "./assets/images/t7.png";
import t8 from "./assets/images/t8.png";

import hump from "./assets/images/hump.png";

import seashepherd2 from "./assets/images/seashepherd2.png";
import edward from "./assets/images/edward.png";
import firefly from "./assets/images/firefly.png";
import earth from "./assets/images/earth.png";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation } from "react-router-dom";

import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";

import { YoutubeVideo } from "./components/YoutubeVideo";
import { Round } from "./components/Round";

import { RegisterWhiteList } from "./components/RegisterWhiteList";

import { Button, Typography } from "@mui/material";
import { HomeLogin } from "./components/HomeLogin";

import { analytics } from "./index";

export const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#82C44C",
    },
    secondary: {
      main: "#FFFFFF",
    },
    info: {
      main: "#469108",
    },
    success: {
      main: "#82C44C",
    },
  },
});

type WhitelistRound = {
  nbSpots: number;
  totalSpots: number;
  openingDate: string;
  closingDate: string;
  status: string;
  MLCPrice: number;
  discountPercent: string;
  round: number;
};

function App() {
  const desktop = useMediaQuery("(min-width:600px)");

  const [previousRound, setpreviousRound] = useState<WhitelistRound>({
    nbSpots: 0,
    totalSpots: 0,
    openingDate: "",
    closingDate: "",
    status: "",
    MLCPrice: 0,
    discountPercent: "0",
    round: 0,
  });
  const [currentRound, setcurrentRound] = useState<WhitelistRound>({
    nbSpots: 0,
    totalSpots: 0,
    openingDate: "",
    closingDate: "",
    status: "",
    MLCPrice: 0,
    discountPercent: "0",
    round: 0,
  });
  const [nextRound, setnextRound] = useState<WhitelistRound>({
    nbSpots: 0,
    totalSpots: 0,
    openingDate: "",
    closingDate: "",
    status: "",
    MLCPrice: 0,
    discountPercent: "0",
    round: 0,
  });

  const [totalInvestFormerRounds, setTotalInvestFormerRounds] = useState(0);

  const [openRegisterMenu, setOpenRegisterMenu] = useState<{ open: boolean; round: number | null }>({
    open: false,
    round: null,
  });

  const [roundOpen, setRoundOpen] = useState(1);

  const handleOpenRegisterWhiteListMenu = (round?: number, close?: boolean) => {
    if (round) {
      setOpenRegisterMenu({ open: true, round: round });

      analytics?.logEvent("click_REGISTER_WL_BUTTON", { name: "register_whitelist" });
    }
    if (close) {
      setOpenRegisterMenu({ open: false, round: null });
    }
  };

  const [login, setLogin] = useState(false);
  const [intro, setIntro] = useState(true);

  const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  };
  let query = useQuery();

  useEffect(() => {
    getNbWhitelisted();
    const loginpage = query.get("loginpage") || "";
    if (loginpage === "true") {
      setIntro(false);
      setLogin(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNbWhitelisted = async () => {
    try {
      const getWhiteListRequest = await fetch(`${process.env.REACT_APP_API_URL}/readWhitelist`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const getWhiteListJSON = await getWhiteListRequest.json();
      const { previousRound, currentRound, nextRound, totalInvestmentOnFormerRounds } = getWhiteListJSON.data;

      setpreviousRound(previousRound);
      setcurrentRound(currentRound);
      setnextRound(nextRound);
      setTotalInvestFormerRounds(totalInvestmentOnFormerRounds);

      if (previousRound.status === "opened") {
        // setRoundOpen(2);
      }
      if (currentRound.status === "opened") {
        setRoundOpen(currentRound.round);
      }
      if (nextRound.status === "opened") {
        // setRoundOpen(4);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGoToRegister = () => {
    setLogin(false);
    setTimeout(() => {
      scrollTo("#registerWhitelist");
    }, 750);
  };

  const scrollTo = (el: string) => {
    const section = document.querySelector(el);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {intro && localStorage.getItem("first_visit") !== "false" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background: "#0F0E1C",
            width: "100%",
          }}
        >
          <img width={desktop ? "20%" : "50%"} height='auto' src={logo} alt='logo' loading='lazy' style={{ marginTop: "50px" }}></img>

          <Typography
            style={{
              color: "#FFFFFF",
              fontFamily: "Alphakind",
              fontWeight: 400,
              fontSize: desktop ? "48px" : "32px",
              lineHeight: desktop ? "48px" : "32px",
              textAlign: "center",
              marginTop: "50px",
              width: desktop ? "50%" : "75%",
            }}
          >
            If you are here...
          </Typography>
          <Typography
            style={{
              color: "#FFFFFF",
              fontFamily: "Nunito",
              fontWeight: 700,
              fontSize: desktop ? "32px" : "24px",
              lineHeight: "44px",
              textAlign: "center",
              marginTop: "10px",
              width: desktop ? "50%" : "75%",
            }}
          >
            You got invited to the World's First Ecoverse private sale
          </Typography>
          <Button
            variant='contained'
            color='info'
            style={{
              width: desktop ? "auto" : "75%",
              borderRadius: "12px",
              color: "white",
              fontSize: desktop ? "32px" : "24px",
              fontFamily: "Alphakind",
              textTransform: "none",
              marginBottom: desktop ? "50px" : "30px",
              marginTop: "50px",
              border: "2px solid white",
              boxShadow: "0px 0px 10px #ffffff",
              padding: "0px 50px",
            }}
            onClick={() => {
              setIntro(false);
              localStorage.setItem("first_visit", "false");
              analytics?.logEvent("click_DISCOVER_BUTTON", { name: "discover" });
            }}
          >
            Discover
          </Button>
          <img src={edward} alt='Edward' style={{ width: desktop ? "40%" : "80%" }} />
        </div>
      ) : login ? (
        <HomeLogin setLogin={setLogin} handleGoToRegister={handleGoToRegister} />
      ) : (
        <div className='App'>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              background: "linear-gradient(180deg, #0F0E1C 0%, #1C0F6B 100%)",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
                position: "fixed",
                top: "0",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                padding: "10px 40px",
                zIndex: 10,
                background: "#0f0e1c87",
              }}
            >
              <Button
                style={{
                  fontFamily: "Nunito",
                  color: "#82C44C",
                  fontSize: desktop ? "16px" : "14px",
                  letterSpacing: "2%",
                  borderRadius: "12px",
                  textTransform: "none",
                  padding: "5px 10px",
                }}
                variant='outlined'
                color='primary'
                size='small'
                onClick={() => {
                  scrollTo("#registerWhitelist");
                }}
              >
                Private sale is now open!
              </Button>
              <a
                href='https://whitepaper.mylovelyplanet.org/'
                target='_blank'
                rel='noreferrer'
                style={{ color: "white", margin: desktop ? "0  25px" : "0  10px", textDecoration: "none", fontFamily: "Nunito" }}
              >
                Whitepaper
              </a>
              <Button
                style={{
                  fontFamily: "Nunito",
                  color: "#82C44C",
                  fontSize: desktop ? "16px" : "14px",
                  letterSpacing: "2%",
                  borderRadius: "12px",
                  textTransform: "none",
                  padding: "5px 10px",
                  marginRight: desktop ? "25px" : "10px",
                }}
                variant='outlined'
                color='primary'
                size='small'
                onClick={() => {
                  setLogin(true);
                }}
              >
                Log In
              </Button>
            </div>
            <div style={{ width: "100%" }}>
              <img width={desktop ? "20%" : "50%"} height='auto' src={logo} alt='logo' loading='lazy' style={{ marginTop: "95px" }}></img>
            </div>
            <Typography
              style={{
                color: "#82C44C",
                fontFamily: "Nunito",
                fontWeight: 700,
                fontSize: desktop ? "32px" : "24px",
                lineHeight: "44px",
                textAlign: "center",
                marginTop: "52px",
              }}
            >
              Play - Protect - Earn
            </Typography>
            <Typography
              style={{
                color: "#FFFFFF",
                fontFamily: "Alphakind",
                fontWeight: 400,
                fontSize: desktop ? "48px" : "32px",
                lineHeight: desktop ? "48px" : "32px",
                textAlign: "center",
                marginTop: "15px",
                width: desktop ? "50%" : "75%",
              }}
            >
              We launched the first crypto coin that works for the planet
            </Typography>
            <YoutubeVideo embedId='TZi5I2s_DFc' title='Everything you need to know is in the video!' />
            <div
              style={{
                width: "100%",
                height: desktop ? "244px" : "154px",
                background: "linear-gradient(180deg, rgba(15, 14, 28, 0.5) 0%, rgba(28, 15, 107, 0) 77.43%)",
              }}
            >
              <img
                src={coin}
                width={desktop ? "25%" : "50%"}
                className='rotate'
                style={{ transform: desktop ? "translateY(-75px)" : "translateY(-50px)" }}
                alt='coin'
              />
            </div>
          </div>
          <div
            id='registerWhitelist'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              background: "#1C0F6B",
              width: "100%",
            }}
          >
            {true ? (
              <Button
                variant='contained'
                color='info'
                disabled={!roundOpen}
                style={{
                  padding: "12px",
                  width: desktop ? "auto" : "75%",
                  borderRadius: "12px",
                  color: "white",
                  fontSize: desktop ? "24px" : "16px",
                  fontFamily: "Alphakind",
                  textTransform: "none",
                  marginBottom: desktop ? "150px" : "75px",
                  marginTop: "60px",
                  border: "2px solid white",
                  boxShadow: "0px 0px 10px #ffffff",
                }}
                onClick={() => handleOpenRegisterWhiteListMenu(roundOpen)}
              >
                {roundOpen ? `Subscribe for Investment` : 'Investment is closed at the moment'}
              </Button>
            ): "Round Closed"}
            {totalInvestFormerRounds > 0 ? (
              <Typography
                style={{
                  color: "#FFFFFF",
                  fontFamily: "Alphakind",
                  fontWeight: 400,
                  fontSize: desktop ? "32px" : "24px",
                  lineHeight: desktop ? "32px" : "24px",
                  textAlign: "center",
                  marginBottom: "50px",

                  width: desktop ? "50%" : "75%",
                }}
              >
                {`Total Investments on former rounds: ${new Intl.NumberFormat("fr-FR", {
                  style: "currency",
                  currency: "EUR",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(totalInvestFormerRounds)}`}
              </Typography>
            ) : null}

            {nextRound.round && <Round
              round={nextRound.round}
              title='Next round'
              status={nextRound.status}
              nbSpots={nextRound.nbSpots}
              totalSpots={nextRound.totalSpots}
              openingDate={nextRound.openingDate}
              closingDate={nextRound.closingDate}
              MLCPrice={nextRound.MLCPrice}
              discountPercent={nextRound.discountPercent}
              handleOpenRegisterWhiteListMenu={handleOpenRegisterWhiteListMenu}
            />}
            {currentRound.round && <Round
              round={currentRound.round}
              title='Current round'
              status={currentRound.status}
              nbSpots={currentRound.nbSpots}
              totalSpots={currentRound.totalSpots}
              openingDate={currentRound.openingDate}
              closingDate={currentRound.closingDate}
              MLCPrice={currentRound.MLCPrice}
              discountPercent={currentRound.discountPercent}
              handleOpenRegisterWhiteListMenu={handleOpenRegisterWhiteListMenu}
            />}
            {previousRound.round && <Round
              round={previousRound.round}
              title='Previous round'
              status={previousRound.status}
              nbSpots={previousRound.nbSpots}
              totalSpots={previousRound.totalSpots}
              openingDate={previousRound.openingDate}
              closingDate={previousRound.closingDate}
              MLCPrice={previousRound.MLCPrice}
              discountPercent={previousRound.discountPercent}
              handleOpenRegisterWhiteListMenu={handleOpenRegisterWhiteListMenu}
            />}

            <RegisterWhiteList
              openRegisterMenu={openRegisterMenu}
              handleOpenRegisterWhiteListMenu={handleOpenRegisterWhiteListMenu}
              getNbWhitelisted={getNbWhitelisted}
              setLogin={setLogin}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              background: "#1C0F6B",
              width: "100%",
            }}
          >
            <Typography
              style={{
                color: "#FFFFFF",
                fontFamily: "Alphakind",
                fontWeight: 400,
                fontSize: desktop ? "48px" : "32px",
                lineHeight: desktop ? "48px" : "32px",
                textAlign: "center",
                marginTop: desktop ? "250px" : "150px",
                width: desktop ? "50%" : "75%",
              }}
            >
              We have established strong partnership with big names
            </Typography>
            <Typography
              style={{
                color: "#FFFFFF",
                fontFamily: "Nunito",
                fontWeight: 400,
                fontSize: desktop ? "24px" : "18px",
                lineHeight: "28px",
                textAlign: "center",
                marginTop: "42px",
                width: desktop ? "50%" : "75%",
              }}
            >
              Among other partners, we have been selected by Ubisoft amongst 200 international start-ups to follow their accelerator program.
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "start",
                width: "80%",

                marginTop: desktop ? "80px" : "20px",
              }}
            >
              <img src={bpi} alt='partner' style={{ width: desktop ? "15%" : "22%" }} />
              <img src={banquepostale} alt='partner' style={{ width: desktop ? "15%" : "22%" }} />
              <img src={ubisoft} alt='partner' style={{ width: desktop ? "15%" : "22%" }} />
              <img src={kkbb} alt='partner' style={{ width: desktop ? "15%" : "22%" }} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                width: "80%",
              }}
            >
              <img src={region} alt='partner' style={{ width: desktop ? "15%" : "22%", margin: desktop ? "20px 50px" : "10px 10px" }} />
              <img src={technopole} alt='partner' style={{ width: desktop ? "15%" : "22%", margin: desktop ? "20px 50px" : "10px 10px" }} />
              <img src={unity} alt='partner' style={{ width: desktop ? "15%" : "22%", margin: desktop ? "20px 50px" : "10px 10px" }} />
            </div>

            <Typography
              style={{
                color: "#FFFFFF",
                fontFamily: "Nunito",
                fontWeight: 400,
                fontSize: desktop ? "24px" : "18px",
                lineHeight: "28px",
                textAlign: "center",
                marginTop: desktop ? "50px" : "20px",
                width: desktop ? "50%" : "75%",
              }}
            >
              We partnered with serious and renowned NGO's
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                width: "90%",
                marginTop: desktop ? "80px" : "20px",
                marginBottom: desktop ? "140px" : "80px",
              }}
            >
              <img src={eden} alt='partner' style={{ width: desktop ? "175px" : "19%" }} />
              <img src={plasticbank} alt='partner' style={{ width: desktop ? "175px" : "19%" }} />
              <img src={seashepherd2} alt='partner' style={{ width: desktop ? "175px" : "19%" }} />
              <img src={spa} alt='partner' style={{ width: desktop ? "175px" : "19%" }} />
              <img src={toitpourlesabeilles} alt='partner' style={{ width: desktop ? "175px" : "19%" }} />
            </div>
            <img src={image4} alt='landscape' loading='lazy' style={{ maxWidth: "100%" }} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              background: "#0F0E1C",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: desktop ? "row" : "column-reverse",
                justifyContent: desktop ? "space-around" : "center",
                alignItems: desktop ? "center" : "space-around",
                marginTop: "140px",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", flex: 1 }}>
                <Typography
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Alphakind",
                    fontWeight: 400,
                    fontSize: desktop ? "48px" : "32px",
                    textAlign: "center",
                    width: "85%",
                    lineHeight: desktop ? "55px" : "36px",
                  }}
                >
                  100 million nature-lovers saving the planet
                </Typography>
                <Typography
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Nunito",
                    fontWeight: 400,
                    fontSize: desktop ? "24px" : "18px",
                    textAlign: "center",
                    width: "85%",
                    marginTop: "32px",
                  }}
                >
                  My Lovely Planet is a fun game to protect the planet while playing with your friends on your smartphone.
                </Typography>
                <ul style={{ marginTop: "80px", display: "flex", flexDirection: "column", alignItems: "start" }}>
                  <li style={{ display: "flex", alignItems: "center", marginBottom: "30px" }}>
                    <img src={firefly} alt='firefly' height='30px' style={{ marginRight: "10px" }} />
                    <Typography
                      style={{
                        color: "#FFFFFF",
                        fontFamily: "Nunito",
                        fontWeight: 400,
                        fontSize: desktop ? "24px" : "18px",
                        textAlign: "start",
                        width: "90%",
                      }}
                    >
                      1 billion trees{" "}
                      <span style={{ opacity: 0.75 }}>
                        {" "}
                        <br />
                        planted by 2030
                      </span>
                    </Typography>{" "}
                  </li>
                  <li style={{ display: "flex", alignItems: "center", marginBottom: "30px" }}>
                    <img src={firefly} alt='firefly' height='30px' style={{ marginRight: "10px", transform: "rotate(-75deg)" }} />
                    <Typography
                      style={{
                        color: "#FFFFFF",
                        fontFamily: "Nunito",
                        fontWeight: 400,
                        fontSize: desktop ? "24px" : "18px",
                        textAlign: "start",
                        width: "90%",
                      }}
                    >
                      1 million tons of plastic{" "}
                      <span style={{ opacity: 0.75 }}>
                        {" "}
                        <br />
                        cleaned from the oceans
                      </span>
                    </Typography>{" "}
                  </li>
                  <li style={{ display: "flex", alignItems: "center", marginBottom: "30px" }}>
                    <img src={firefly} alt='firefly' height='30px' style={{ marginRight: "10px", transform: "rotate(75deg)" }} />
                    <Typography
                      style={{
                        color: "#FFFFFF",
                        fontFamily: "Nunito",
                        fontWeight: 400,
                        fontSize: desktop ? "24px" : "18px",
                        textAlign: "start",
                        width: "90%",
                      }}
                    >
                      Protect biodiversity{" "}
                      <span style={{ opacity: 0.75 }}>
                        {" "}
                        <br />
                        and endangered species
                      </span>
                    </Typography>{" "}
                  </li>
                </ul>
              </div>
              <div style={{ flex: 1, display: "flex", justifyContent: "start", marginBottom: desktop ? "0" : "30px" }}>
                <img src={edward} alt='Edward' style={{ width: "60%" }} />
                <img src={earth} alt='earth' style={{ width: "60%", height: "60%", transform: "translate(-40%, 0px)" }} />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              background: "linear-gradient(180deg, #0F0E1C 0%, #1C0F6B 100%)",
              width: "100%",
            }}
          >
            <Typography
              style={{
                color: "#FFFFFF",
                fontFamily: "Alphakind",
                fontWeight: 400,
                fontSize: desktop ? "48px" : "32px",
                lineHeight: desktop ? "46px" : "32px",
                textAlign: "center",
                marginTop: desktop ? "200px" : "100px",
                width: desktop ? "50%" : "75%",
              }}
            >
              We have won the biggest awards for game for the environment
            </Typography>

            <Typography
              style={{
                color: "#FFFFFF",
                fontFamily: "Nunito",
                fontWeight: 400,
                fontSize: desktop ? "24px" : "18px",
                lineHeight: desktop ? "28px" : "22px",
                textAlign: "center",
                marginTop: "42px",
                width: desktop ? "50%" : "75%",
              }}
            >
              Unity is the "Microsoft" of game engine technology. It awards each years the best games for a cause in a worldwide competition.
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                width: desktop ? "80%" : "90%",
                marginTop: "20px",
              }}
            >
              <img alt='award' src={Icone_Unity} style={{ width: "40%", zIndex: 2, transform: "translateY(30%)" }} />
              <img alt='award' src={hump} style={{ width: "30%" }} />
            </div>

            <Typography
              style={{
                color: "#FFFFFF",
                fontFamily: "Alphakind",
                fontWeight: 400,
                fontSize: desktop ? "48px" : "32px",
                lineHeight: desktop ? "46px" : "32px",
                textAlign: "center",
                marginTop: desktop ? "200px" : "120px",
                width: desktop ? "50%" : "75%",
              }}
            >
              Team
            </Typography>

            <div
              style={{
                display: "flex",
                flexDirection: desktop ? "row" : "column",
                justifyContent: desktop ? "space-around" : "start",
                alignItems: desktop ? "start" : "center",
                width: desktop ? "80%" : "90%",
                marginTop: desktop ? "80px" : "50px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: desktop ? "22%" : "80%",
                  margin: desktop ? "20px" : "10px",
                }}
              >
                <img src={t3} alt='team' style={{ width: desktop ? "175px" : "150px" }} />
                <Typography
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Nunito",
                    fontWeight: 400,
                    fontSize: desktop ? "20px" : "16px",
                    lineHeight: desktop ? "28px" : "22px",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  Clément Le Bras
                </Typography>
                <Typography
                  style={{
                    color: "#E8FFFF",
                    fontFamily: "Nunito",
                    fontWeight: 400,
                    fontSize: desktop ? "16px" : "14px",
                    lineHeight: desktop ? "18px" : "16px",
                    textAlign: "center",
                  }}
                >
                  CEO, Social entrepreneur
                </Typography>
                <Typography
                  style={{
                    color: "#E8FFFF",
                    fontFamily: "Nunito",
                    fontWeight: 400,
                    fontSize: desktop ? "16px" : "14px",
                    lineHeight: desktop ? "18px" : "16px",
                    textAlign: "center",
                  }}
                >
                  Founder of Lilo search engine
                </Typography>
                {/* <a href='https://www.linkedin.com/in/cl%C3%A9ment-le-bras-2236aa37/' target='_blank' rel='noreferrer'>
                  <img src={linkedin} alt='linkedin' style={{ width: "20px" , marginTop:"5px"}} />
                </a> */}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: desktop ? "22%" : "80%",
                  margin: desktop ? "20px" : "10px",
                }}
              >
                <img src={t4} alt='team' style={{ width: desktop ? "175px" : "150px" }} />
                <Typography
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Nunito",
                    fontWeight: 400,
                    fontSize: desktop ? "20px" : "16px",
                    lineHeight: desktop ? "28px" : "22px",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  Xavier Lepretre
                </Typography>
                <Typography
                  style={{
                    color: "#E8FFFF",
                    fontFamily: "Nunito",
                    fontWeight: 400,
                    fontSize: desktop ? "16px" : "14px",
                    lineHeight: desktop ? "18px" : "16px",
                    textAlign: "center",
                  }}
                >
                  Expert Blockhain Advisor, Blockchain Expert
                </Typography>
                <Typography
                  style={{
                    color: "#E8FFFF",
                    fontFamily: "Nunito",
                    fontWeight: 400,
                    fontSize: desktop ? "16px" : "14px",
                    lineHeight: desktop ? "18px" : "16px",
                    textAlign: "center",
                  }}
                >
                  Co-founder B9Labs
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: desktop ? "22%" : "80%",
                  margin: desktop ? "20px" : "10px",
                }}
              >
                <img src={t2} alt='team' style={{ width: desktop ? "175px" : "150px" }} />
                <Typography
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Nunito",
                    fontWeight: 400,
                    fontSize: desktop ? "20px" : "16px",
                    lineHeight: desktop ? "28px" : "22px",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  Guillaume Gras
                </Typography>
                <Typography
                  style={{
                    color: "#E8FFFF",
                    fontFamily: "Nunito",
                    fontWeight: 400,
                    fontSize: desktop ? "16px" : "14px",
                    lineHeight: desktop ? "18px" : "16px",
                    textAlign: "center",
                  }}
                >
                  Technical Project Manager
                </Typography>
                <Typography
                  style={{
                    color: "#E8FFFF",
                    fontFamily: "Nunito",
                    fontWeight: 400,
                    fontSize: desktop ? "16px" : "14px",
                    lineHeight: desktop ? "18px" : "16px",
                    textAlign: "center",
                  }}
                >
                  Founder of Weedooit
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  maxWidth: desktop ? "22%" : "80%",
                  margin: desktop ? "20px" : "10px",
                }}
              >
                <img src={t8} alt='team' style={{ width: desktop ? "175px" : "150px" }} />
                <Typography
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Nunito",
                    fontWeight: 400,
                    fontSize: desktop ? "20px" : "16px",
                    lineHeight: desktop ? "28px" : "22px",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  Lamya Essemlali
                </Typography>
                <Typography
                  style={{
                    color: "#E8FFFF",
                    fontFamily: "Nunito",
                    fontWeight: 400,
                    fontSize: desktop ? "16px" : "14px",
                    lineHeight: desktop ? "18px" : "16px",
                    textAlign: "center",
                  }}
                >
                  Ethical Advisor
                </Typography>
                <Typography
                  style={{
                    color: "#E8FFFF",
                    fontFamily: "Nunito",
                    fontWeight: 400,
                    fontSize: desktop ? "16px" : "14px",
                    lineHeight: desktop ? "18px" : "16px",
                    textAlign: "center",
                  }}
                >
                  President Sea Shepherd France
                </Typography>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: desktop ? "row" : "column",
                justifyContent: desktop ? "space-around" : "start",
                alignItems: desktop ? "start" : "center",
                width: desktop ? "80%" : "90%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: desktop ? "22%" : "80%",
                  margin: desktop ? "20px" : "10px",
                }}
              >
                <img src={t5} alt='team' style={{ width: desktop ? "175px" : "150px" }} />
                <Typography
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Nunito",
                    fontWeight: 400,
                    fontSize: desktop ? "20px" : "16px",
                    lineHeight: desktop ? "28px" : "22px",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  Eva Catrix
                </Typography>
                <Typography
                  style={{
                    color: "#E8FFFF",
                    fontFamily: "Nunito",
                    fontWeight: 400,
                    fontSize: desktop ? "16px" : "14px",
                    lineHeight: desktop ? "18px" : "16px",
                    textAlign: "center",
                  }}
                >
                  3D game designer
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: desktop ? "22%" : "80%",
                  margin: desktop ? "20px" : "10px",
                }}
              >
                <img src={t6} alt='team' style={{ width: desktop ? "175px" : "150px" }} />
                <Typography
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Nunito",
                    fontWeight: 400,
                    fontSize: desktop ? "20px" : "16px",
                    lineHeight: desktop ? "28px" : "22px",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  Sandro Teixeira
                </Typography>
                <Typography
                  style={{
                    color: "#E8FFFF",
                    fontFamily: "Nunito",
                    fontWeight: 400,
                    fontSize: desktop ? "16px" : "14px",
                    lineHeight: desktop ? "18px" : "16px",
                    textAlign: "center",
                  }}
                >
                  Senior Unity Developer
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: desktop ? "22%" : "80%",
                  margin: desktop ? "20px" : "10px",
                }}
              >
                <img src={t1} alt='team' style={{ width: desktop ? "175px" : "150px" }} />
                <Typography
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Nunito",
                    fontWeight: 400,
                    fontSize: desktop ? "20px" : "16px",
                    lineHeight: desktop ? "28px" : "22px",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  Raphaël Levy
                </Typography>
                <Typography
                  style={{
                    color: "#E8FFFF",
                    fontFamily: "Nunito",
                    fontWeight: 400,
                    fontSize: desktop ? "16px" : "14px",
                    lineHeight: desktop ? "18px" : "16px",
                    textAlign: "center",
                  }}
                >
                  Fullstack and Blockchain Developer
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: desktop ? "22%" : "80%",
                  margin: desktop ? "20px" : "10px",
                }}
              >
                <img src={t7} alt='team' style={{ width: desktop ? "175px" : "150px" }} />
                <Typography
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Nunito",
                    fontWeight: 400,
                    fontSize: desktop ? "20px" : "16px",
                    lineHeight: desktop ? "28px" : "22px",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  Théo Gautier
                </Typography>
                <Typography
                  style={{
                    color: "#E8FFFF",
                    fontFamily: "Nunito",
                    fontWeight: 400,
                    fontSize: desktop ? "16px" : "14px",
                    lineHeight: desktop ? "18px" : "16px",
                    textAlign: "center",
                  }}
                >
                  Unity Developer and game design
                </Typography>
              </div>
            </div>

            <YoutubeVideo
              embedId='doqHdscb4Yo'
              title="Congratulation, you have reached the bottom of our website. To thank you, here is the game trailer of My Lovely Planet. It's confidential but we trust you not to share it !"
            />
            <div
              style={{
                width: "100%",

                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "start",
                padding: "20px 0",
                minHeight: "100px",
                background: "#0f0e1c31",
              }}
            >
              <div style={{ display: "flex", justifyContent: "start", width: "100%" }}>
                <a
                  href='https://www.mylovelyplanet.org/privacy-policy'
                  target='_blank'
                  rel='noreferrer'
                  style={{
                    color: "white",
                    margin: desktop ? "0 20px " : "0 7px",
                    fontSize: desktop ? "16px" : "12px",
                    textDecoration: "none",
                    fontFamily: "Nunito",
                  }}
                >
                  Privacy Policy
                </a>
                <a
                  href='https://www.mylovelyplanet.org/private-sale-terms-and-conditions'
                  target='_blank'
                  rel='noreferrer'
                  style={{
                    color: "white",
                    margin: desktop ? "0 20px " : "0 7px",
                    fontSize: desktop ? "16px" : "12px",
                    textDecoration: "none",
                    fontFamily: "Nunito",
                  }}
                >
                  {`Terms & conditions`}
                </a>
                <a
                  href='https://whitepaper.mylovelyplanet.org/'
                  target='_blank'
                  rel='noreferrer'
                  style={{
                    color: "white",
                    margin: desktop ? "0 20px " : "0 7px",
                    fontSize: desktop ? "16px" : "12px",
                    textDecoration: "none",
                    fontFamily: "Nunito",
                  }}
                >
                  Whitepaper
                </a>
                <a
                  href='https://mylovelyplanet.org/'
                  target='_blank'
                  rel='noreferrer'
                  style={{
                    color: "white",
                    margin: desktop ? "0 20px " : "0 7px",
                    fontSize: desktop ? "16px" : "12px",
                    textDecoration: "none",
                    fontFamily: "Nunito",
                  }}
                >
                  mylovelyplanet.org
                </a>
                {desktop ? (
                  <Typography
                    style={{ fontFamily: "Nunito", color: "white", margin: desktop ? "0 20px " : "0 7px", fontSize: desktop ? "16px" : "12px" }}
                  >
                    contact us: support@getimagine.org
                  </Typography>
                ) : null}
              </div>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "end", marginTop: "15px", width: "100%" }}>
                <Typography style={{ fontFamily: "Nunito", color: "#ffffffb3", marginRight: "35px" }}>IMAGINE</Typography>
                <Typography style={{ fontFamily: "Nunito", color: "#ffffffb3", marginRight: "35px" }}>Maison de la Technopole</Typography>
                <Typography style={{ fontFamily: "Nunito", color: "#ffffffb3", marginRight: "35px" }}>6 RUE LÉONARD DE VINCI</Typography>
                <Typography style={{ fontFamily: "Nunito", color: "#ffffffb3", marginRight: "35px" }}>CS 20119</Typography>
                <Typography style={{ fontFamily: "Nunito", color: "#ffffffb3", marginRight: "35px" }}>53001 LAVAL CEDEX</Typography>
                <Typography style={{ fontFamily: "Nunito", color: "#ffffffb3", marginRight: "35px" }}>SIREN: 893691758</Typography>
                {desktop ? null : (
                  <Typography
                    style={{ fontFamily: "Nunito", color: "white", alignSelf: "center", fontSize: desktop ? "24px" : "16px", marginTop: "15px" }}
                  >
                    contact us: support@getimagine.org
                  </Typography>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </ThemeProvider>
  );
}

export default App;
