import React from "react";
import ReactDOM from "react-dom";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import "./index.css";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import InvestPage from "./InvestPage";
// Your web app's Firebase configuration
// Your web app's Firebase configuration
import firebase from "firebase/app";
import Adm from "./Adm";

require("firebase/firestore");
require("firebase/functions");
require("firebase/analytics");

ReactDOM.render(
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<App />} />
        <Route path='/invest' element={<InvestPage />} />
        <Route path='/adm' element={<Adm />} />
      </Routes>
    </BrowserRouter>
  </LocalizationProvider>
  ,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
const firebaseConfig = {
  apiKey: "AIzaSyA8aOd8NXRq_Sl0EcH510OPuX-qf9HDUVE",
  authDomain: "sale-mylovelycoin.firebaseapp.com",
  projectId: "sale-mylovelycoin",
  storageBucket: "sale-mylovelycoin.appspot.com",
  messagingSenderId: "949645625603",
  appId: "1:949645625603:web:833208a1e98c9ef22e0136",
  measurementId: "G-CD11D3EL9V",
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const functions = firebase.functions();
export const analytics = firebase.analytics();
