import React, { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import logo from "./../assets/images/Page1_Logo.png";
import CircularProgress from "@mui/material/CircularProgress";

import { TextField } from "@mui/material";

interface InvestmentLoginProps {
  email: string;
  handleLog: (status: boolean) => void;
}

type LoginStep = "login" | "resetPassword" | "setNewPassword";

export const InvestmentLogin: React.FC<InvestmentLoginProps> = ({ email, handleLog }) => {
  const [loginStep, setLoginStep] = useState<LoginStep>("login");

  const [password, setPassword] = useState("");
  const [temporaryPassword, setTemporaryPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [loading, setLoading] = useState(false);
  const [helper, setHelper] = useState("");
  const desktop = useMediaQuery("(min-width:600px)");

  const handleLogin = async () => {
    try {
      if (!email || !password) {
        console.log("Data missing");
        return;
      }
      setHelper("");
      setLoading(true);

      const login = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          email,
          password,
        }),
      });
      const loginResponse = await login.json();
      setLoading(false);
      if (loginResponse?.data?.status !== "authorized" || !loginResponse?.data?.token) {
        setHelper("email/password do not match");
      }
      if (loginResponse?.data?.status === "authorized" && loginResponse?.data?.token) {
        localStorage.setItem("token", loginResponse?.data?.token);
        handleLog(true);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setHelper("Oups an something wrong happened, please try again");
    }
  };

  const handleResetPassword = async () => {
    try {
      if (!email) {
        console.log("Data missing");
        return;
      }
      setHelper("");
      setLoading(true);
      const resetPassword = await fetch(`${process.env.REACT_APP_API_URL}/resetPassword`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          email,
        }),
      });
      const resetPasswordResponse = await resetPassword.json();
      setLoading(false);

      if (resetPasswordResponse?.data?.status === "reset") {
        setTemporaryPassword("");
        setLoginStep("setNewPassword");
        return;
      }

      if (resetPasswordResponse?.data?.status === "accountNotFound") {
        setHelper("Email not found");
        return;
      }
      if (resetPasswordResponse?.data?.status === "internal server error") {
        setHelper("Oups an error happened, please try again");
        return;
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setHelper("Oups an something wrong happened, please try again");
    }
  };

  const handleSetNewPassword = async () => {
    try {
      setHelper("");
      if (!email || !newPassword || !newPasswordConfirmation) {
        console.log("Data missing");
        return;
      }
      if (newPassword !== newPasswordConfirmation) {
        setHelper("Passwords do not match");
      }
      setLoading(true);
      const setNewPassword = await fetch(`${process.env.REACT_APP_API_URL}/setNewPassword`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          email,
          temporaryPassword,
          newPassword,
        }),
      });
      const setNewPasswordResponse = await setNewPassword.json();
      setLoading(false);

      if (setNewPasswordResponse?.data?.status === "passwordReset") {
        setLoginStep("login");
        handleLog(true);
        return;
      }

      if (setNewPasswordResponse?.data?.status === "invalidPassword") {
        setHelper("Invalid temporary password");
        return;
      }
      if (setNewPasswordResponse?.data?.status === "accountNotFound") {
        setHelper("Email not found");
        return;
      }
      if (setNewPasswordResponse?.data?.status === "internal server error") {
        setHelper("Oups an error happened, please try again");
        return;
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setHelper("Oups an something wrong happened, please try again");
    }
  };
  const inputStyle = { WebkitBoxShadow: "0 0 0 1000px #171535 inset" };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "#0F0E1C",
        width: "100%",
        height: "100vh",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "50px", width: "100%" }}>
        <img width={desktop ? "20%" : "50%"} height='auto' src={logo} alt='logo' loading='lazy' style={{ marginBottom: "30px" }}></img>
        <Typography
          style={{
            color: "#FFFFFF",
            fontFamily: "Alphakind",
            fontWeight: 400,
            fontSize: desktop ? "48px" : "32px",
            lineHeight: desktop ? "46px" : "30px",
            textAlign: "center",
            width: "75%",
          }}
        >
          Welcome back
        </Typography>

        {loginStep === "login" ? (
          <Typography
            style={{
              color: "#82C44C",
              fontFamily: "Nunito",
              fontWeight: 700,
              fontSize: desktop ? "32px" : "24px",
              lineHeight: desktop ? "44px" : "26px",
              textAlign: "center",
              marginTop: "15px",
              marginBottom: "15px",
            }}
          >
            Please log in
          </Typography>
        ) : loginStep === "resetPassword" ? (
          <Typography
            style={{
              color: "#82C44C",
              fontFamily: "Nunito",
              fontWeight: 700,
              fontSize: desktop ? "32px" : "24px",
              lineHeight: desktop ? "44px" : "26px",
              textAlign: "center",
              marginTop: "15px",
              marginBottom: "15px",
            }}
          >
            Reset your password
          </Typography>
        ) : loginStep === "setNewPassword" ? (
          <Typography
            style={{
              color: "#82C44C",
              fontFamily: "Nunito",
              fontWeight: 700,
              fontSize: desktop ? "32px" : "24px",
              lineHeight: desktop ? "44px" : "26px",
              textAlign: "center",
              marginTop: "15px",
              marginBottom: "15px",
            }}
          >
            Choose a new password
          </Typography>
        ) : null}
      </div>
      <Box
        component='form'
        style={{ marginTop: "25px", padding: "30px 40px", borderRadius: 10, maxWidth: "70%" }}
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
          "& .MuiOutlinedInput-root": {
            borderColor: "white",
          },
        }}
        noValidate
      >
        {loginStep === "login" ? (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <TextField
              id='emailField'
              color='secondary'
              inputProps={{ style: inputStyle }}
              variant='outlined'
              disabled
              value={email}
              error={helper ? true : false}
              helperText={helper ? helper : ""}
            />
            <TextField
              id='passwordField'
              color='secondary'
              inputProps={{ style: inputStyle }}
              label='Password'
              type='password'
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
              value={password}
            />
            <Button
              color='secondary'
              component='button'
              style={{ textDecoration: "underline", margin: "10px" }}
              onClick={() => setLoginStep("resetPassword")}
            >
              Password forgotten?
            </Button>
            {!loading ? (
              <Button variant='contained' style={{ marginTop: "10px" }} color='primary' onClick={handleLogin}>
                Log in
              </Button>
            ) : (
              <CircularProgress color='primary' />
            )}
          </div>
        ) : null}
        {loginStep === "resetPassword" ? (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <TextField
              id='emailField'
              color='secondary'
              inputProps={{ style: inputStyle }}
              variant='outlined'
              disabled
              value={email}
              error={helper ? true : false}
              helperText={helper ? helper : ""}
            />

            <Button
              color='secondary'
              component='button'
              style={{ textDecoration: "underline", margin: "10px" }}
              onClick={() => setLoginStep("login")}
            >
              Back to login
            </Button>
            {!loading ? (
              <Button variant='contained' style={{ marginTop: "10px" }} color='primary' onClick={handleResetPassword}>
                Reset your password
              </Button>
            ) : (
              <CircularProgress color='primary' />
            )}
          </div>
        ) : null}
        {loginStep === "setNewPassword" ? (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <TextField
              id='emailField'
              color='secondary'
              inputProps={{ style: inputStyle }}
              variant='outlined'
              disabled
              value={email}
              error={helper ? true : false}
              helperText={helper ? helper : ""}
            />
            <TextField
              id='temporaryPasswordField'
              color='secondary'
              inputProps={{ style: inputStyle }}
              label='Temporary password received by email'
              type='password'
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTemporaryPassword(e.target.value)}
              value={temporaryPassword}
            />
            <TextField
              id='newPasswordField'
              color='secondary'
              inputProps={{ style: inputStyle }}
              label='New Password'
              type='password'
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value)}
              value={newPassword}
            />
            <TextField
              id='newPasswordConfirmationField'
              color='secondary'
              inputProps={{ style: inputStyle }}
              label='Confirm your new password'
              type='password'
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewPasswordConfirmation(e.target.value)}
              value={newPasswordConfirmation}
            />

            {!loading ? (
              <Button variant='contained' style={{ marginTop: "10px" }} color='primary' onClick={handleSetNewPassword}>
                Change your password
              </Button>
            ) : (
              <CircularProgress color='primary' />
            )}
          </div>
        ) : null}
      </Box>
      <Button sx={{ visibility: "hidden" }} component='button' size='large' variant='contained' color='secondary'>
        Close
      </Button>
    </div>
  );
};
