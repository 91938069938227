import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import StarIcon from "@mui/icons-material/Star";
import useMediaQuery from "@mui/material/useMediaQuery";
interface HowToProps {}

export const HowTo: React.FC<HowToProps> = () => {
  const desktop = useMediaQuery("(min-width:600px)");
  return (
    <div style={{ width: "90%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }} id='register'>
      <Typography
        color='primary'
        style={{
          marginBottom: "30px",
          maxWidth: "75%",
          fontFamily: "Alphakind, cursive",
          textAlign: "center",
          fontSize: desktop ? "48px" : "32px",
          lineHeight: "34px",
        }}
      >
        How to participate?
      </Typography>
      <Box sx={{ background: "#6b52db26", padding: desktop ? "10px 40px" : "10px", borderRadius: 10, maxWidth: desktop ? "70%" : "95%" }}>
        <List sx={{ width: "100%", color: "white" }} color='primary'>
          <ListItem>
            <ListItemIcon>
              <StarIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText
              color='primary'
              primary='Step 1: Register to our whitelist'
              primaryTypographyProps={{ fontFamily: "Nunito", fontSize: desktop ? "1rem" : "0.85rem" }}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <StarIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText
              color='primary'
              primary='Step 2: Once registered to Whitelist, your private investor space will be created. Make sure to check your emails to confirm the creation of your space, attached to your email adress.'
              primaryTypographyProps={{ fontFamily: "Nunito", fontSize: desktop ? "1rem" : "0.85rem" }}
            />
          </ListItem>
        </List>
      </Box>{" "}
    </div>
  );
};
