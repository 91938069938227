import React, { useState, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import InputAdornment from "@mui/material/InputAdornment";

import { useLocation } from "react-router-dom";

interface FormInvestProps {
  round: number;
  currency: string;
  rates?: { MLPUSD: number | null; MLPEUR: number | null; MLPXTZ: number | null; MLCETH: number | null; MLCMATIC: number | null };
  goToNextStep: () => void;
}

function isCrypto(currency: string) {
  return currency === "eth" || currency === "xtz" || currency === "matic"
}

export const FormInvest: React.FC<FormInvestProps> = ({ round, goToNextStep, currency, rates }) => {
  const [email, setEmail] = useState<string>();
  const [investment, setInvestment] = useState<number>(0);
  const [checked, setChecked] = useState<boolean>(false);
  // const [termsChecked, setTermsChecked] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("loading");
  const [loading, setLoading] = useState(false);
  const [investorRound, setInvestorRound] = useState<number | undefined | null>(null);
  const [investorId, setInvestorId] = useState<string | undefined | null>("");
  const [helper, setHelper] = useState<string | undefined | null>("");
  const [rate, setRate] = useState<number | null>(0);

  const desktop = useMediaQuery("(min-width:600px)");

  const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  };
  let query = useQuery();

  useEffect(() => {
    const id = query.get("id");
    setInvestorId(id);
    if (id) {
      fetchInvestorInfo(id);
    }
    if (rates) {
      switch (currency) {
        case "eth": setRate(rates.MLCETH);
          break;
        case "matic": setRate(rates.MLCMATIC);
          break;
        case "xtz": setRate(rates.MLPUSD);
          break;
        case "€": setRate(rates.MLPEUR);
          break;
        default: setRate(rates.MLPUSD);
      }
    }
  }, [currency, query, rates]);

  const fetchInvestorInfo = async (id: string) => {
    try {
      const investorInfo = await fetch(`${process.env.REACT_APP_API_URL}/investorInfo?id=${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const investorInfoResponse = await investorInfo.json();

      const { email, round } = investorInfoResponse.data;

      setEmail(email);
      setInvestorRound(round);
      setStatus("start");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (status === "error") {
      setTimeout(() => {
        setStatus("start");
      }, 2000);
    }
  }, [status]);

  const handleCheckConditions = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleConfirmInvestment = async () => {
    try {
      if (!email || !investment || !currency || !checked || !investorRound) {
        console.log("Data missing");
        return;
      }
      setHelper("");
      setLoading(true);
      const token = localStorage.getItem("token");
      const confirmInvest = await fetch(`${process.env.REACT_APP_API_URL}/confirmInvestment?investorId=${investorId}&token=${token}`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          email,
          investment,
          currency,
          round: investorRound,
        }),
      });
      const confirmInvestResponse = await confirmInvest.json();

      if (confirmInvestResponse.data === "minAmount") {
        setHelper("You haven't reached the minimal investment");
      }
      // if (confirmInvestResponse.data === "maxAmount") {
      //   setHelper("You have reached the maximum investment amount");
      // }

      if (confirmInvestResponse.data === "investmentMade") {
        setHelper("You cannot invest twice");
      }
      if (confirmInvestResponse.data === "success") {
        setStatus(confirmInvestResponse.data);
        goToNextStep();
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setStatus("error");
    }
  };
  const inputStyle = { WebkitBoxShadow: "0 0 0 1000px #171535 inset" };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      {status === "start" ? (
        <Typography
          color='primary'
          style={{ maxWidth: "75%", textAlign: "center", fontFamily: "Nunito", fontSize: desktop ? "36px" : "24px", lineHeight: "34px" }}
        >
          Validate your investment
        </Typography>
      ) : (
        <div style={{ marginTop: "50px" }} />
      )}
      {investorRound === round ? (
        <Box
          component='form'
          style={{ marginTop: "25px", padding: "30px 40px", borderRadius: 10, maxWidth: "70%" }}
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
        >
          {status === "start" ? (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <TextField
                id='emailField'
                variant='outlined'
                error={helper ? true : false}
                helperText={helper ? helper : ""}
                disabled
                value={email}
                color='secondary'
                inputProps={{ style: inputStyle }}
                key='emailField'
              />

              <TextField
                id='outlined-number'
                color='secondary'
                inputProps={{ style: inputStyle }}
                label='Investment'
                type='number'
                required
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end' style={{ width: "20%", display: "flex", justifyContent: "center" }}>
                      {currency === 'xtz' ? 'usdt/c' : currency}
                    </InputAdornment>
                  ),
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInvestment(parseFloat(e.target.value))}
                value={investment}
                key='outlined-number'
              />
              <Typography variant='h6' color='secondary' style={{ maxWidth: "75%" }}>
                =
              </Typography>
              <TextField
                id='outlined-number'
                color='secondary'
                inputProps={{ style: inputStyle }}
                label={`Investment`}
                type='number'
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end' style={{ width: "20%", display: "flex", justifyContent: "center" }}>
                      MLC
                    </InputAdornment>
                  ),
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInvestment(parseInt(e.target.value))}
                value={investment && rate ? investment * rate : 0}
                key='outlined-number2'
              />
              <Typography
                color='primary'
                style={{
                  maxWidth: desktop ? "50%" : "85%",
                  textAlign: "center",
                  fontFamily: "Nunito",
                  fontSize: desktop ? "14px" : "12px",
                  lineHeight: "18px",
                  margin: "10px 0",
                }}
              >
                This corresponding number of My Lovely Coin will be validated once we received your payment. You will be able to claim these as soon
                as the listing is complete and the token goes public (in summer 2023)
              </Typography>
              <FormControlLabel
                control={<Checkbox color='primary' checked={checked} onChange={handleCheckConditions} />}
                label={<Typography>I confirm I transferred the amount of money I indicated</Typography>}
              />
              {/* <FormControlLabel
                control={<Checkbox color='primary' checked={termsChecked} onChange={handleCheckTermsConditions} />}
                label={
                  <Typography>
                    I agree with the{" "}
                    <a
                      href='https://www.mylovelyplanet.org/private-sale-terms-and-conditions'
                      target='_blank'
                      rel='noreferrer'
                      style={{
                        color: "white",
                      }}
                    >{`terms & conditions of Private Sale`}</a>
                  </Typography>
                }
              /> */}
              <Typography
                color='secondary'
                sx={{
                  maxWidth: desktop ? "50%" : "85%",
                  textAlign: "center",
                  fontFamily: "Nunito",
                  fontSize: desktop ? "14px" : "12px",
                  lineHeight: "18px",
                  margin: "10px 0",
                }}
              >
                Pay attention : once you click "CONFIRM" you will not be able to modify the investment you indicated
              </Typography>
              <Typography
                color='primary'
                sx={{
                  maxWidth: desktop ? "50%" : "85%",
                  textAlign: "center",
                  fontFamily: "Nunito",
                  fontSize: desktop ? "14px" : "12px",
                  lineHeight: "18px",
                  margin: "5px 0",
                }}
              >
                Once you confirm :
              </Typography>
              <Typography
                color='primary'
                sx={{
                  maxWidth: desktop ? "50%" : "85%",
                  textAlign: "center",
                  fontFamily: "Nunito",
                  fontSize: desktop ? "14px" : "12px",
                  lineHeight: "18px",
                }}
              >
                1) your investment capability for this round will be closed
              </Typography>
              <Typography
                color='primary'
                sx={{
                  maxWidth: desktop ? "50%" : "85%",
                  textAlign: "center",
                  fontFamily: "Nunito",
                  fontSize: desktop ? "14px" : "12px",
                  lineHeight: "18px",
                  margin: "5px 0",
                }}
              >
                2) we will confirm within 24 to 72 hours that we did receive your payment corresponding to the indicated transfer"
              </Typography>
              {!loading ? (
                <Button
                  variant='contained'
                  style={{ marginTop: "15px" }}
                  color='primary'
                  size='large'
                  onClick={handleConfirmInvestment}
                  disabled={!checked || !investment || (!isCrypto(currency) && investment < 1000)}
                >
                  Confirm
                </Button>
              ) : (
                <CircularProgress color='primary' style={{ marginTop: "15px" }} />
              )}
            </div>
          ) : status === "success" ? (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
              <Typography variant='h3' color='primary' style={{ marginTop: "50px ", marginBottom: "20px" }}>
                Confirmation successful !
              </Typography>
            </div>
          ) : status === "error" ? (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Typography variant='h3' color='primary' style={{ marginTop: "50px " }}>
                Oops something went wrong, please try again
              </Typography>
            </div>
          ) : null}
        </Box>
      ) : status === "loading" ? null : (
        <Typography variant='h3' color='primary' style={{ marginTop: "50px " }}>
          You are not registered for this round
        </Typography>
      )}
    </div>
  );
};
