import React, { useState } from "react";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";

import { FormTransfer } from "./FormTransfer";
import { FormInvest } from "./FormInvest";

function isCrypto(currency: string) {
  return currency === "eth" || currency === "xtz" || currency === "matic"
}

interface InvestmentPopupProps {
  openInvestMenu: {
    open: boolean;
    round: number | null;
  };
  rates?: { MLPUSD: number | null; MLPEUR: number | null; MLPXTZ: number | null; MLCETH: number | null; MLCMATIC: number | null };
  xtzAddress?: string;
  handleOpenInvestMenu: (round?: number, close?: boolean) => void;
  getInvestmentRound: () => void;
  MLCPrice: number;
}

export const InvestmentPopup: React.FC<InvestmentPopupProps> = ({
  openInvestMenu,
  handleOpenInvestMenu,
  getInvestmentRound,
  xtzAddress,
  rates,
  MLCPrice,
}) => {
  const { open, round } = openInvestMenu;

  const [step, setStep] = useState(0);

  const goToNextStep = () => {
    setStep((step) => step + 1);
  };
  const goToPreviousStep = () => {
    setStep((step) => step - 1);
  };
  const [currency, setCurrency] = useState("€");

  const handleClose = () => {
    handleOpenInvestMenu(0, true);
    getInvestmentRound();
    setStep(0);
  };

  const handleSetCurrency = (currency: string) => {
    setCurrency(currency);
  };

  const desktop = useMediaQuery("(min-width:600px)");

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          background: "linear-gradient(180deg, #0F0E1C 0%, #1C0F6B 100%)",
          color: "white",
          height: "100%",
          width: "100%",
          padding: "20px",
          maxWidth: "100%",
          marginRight: desktop ? "auto" : "5px",
          marginLeft: desktop ? "auto" : "5px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: "10px",
          position: "relative",
        },
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Typography
          style={{
            color: "#FFFFFF",
            fontFamily: "Alphakind",
            fontWeight: 400,
            fontSize: desktop ? "48px" : "32px",
            lineHeight: desktop ? "46px" : "30px",
            textAlign: "center",
          }}
        >
          {`Investment`}
        </Typography>
        <Typography
          style={{
            color: "#FFFFFF",
            fontFamily: "Alphakind",
            fontWeight: 400,
            fontSize: desktop ? "22px" : "18px",
            lineHeight: desktop ? "24px" : "20px",
            textAlign: "center",
            marginTop: "10px",
          }}
        >
          {`MLC price: ${MLCPrice} €`}
        </Typography>
        <Typography
          style={{
            color: "#82C44C",
            fontFamily: "Nunito",
            fontWeight: 700,
            fontSize: desktop ? "32px" : "24px",
            lineHeight: desktop ? "44px" : "26px",
            textAlign: "center",
            marginTop: "15px",
            marginBottom: "15px",
          }}
        >
          {`Step ${step + 1}/3`}
        </Typography>
      </div>

      {step === 0 ? (
        <FormTransfer goToNextStep={goToNextStep} handleSetCurrency={handleSetCurrency} currency={currency} xtzAddress={xtzAddress} />
      ) : null}
      {step === 1 && round ? <FormInvest round={round} goToNextStep={goToNextStep} currency={currency} rates={rates} /> : null}
      {step === 2 && round ? (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", maxWidth: "75%" }}>
          <Typography sx={{ marginBottom: "20px", fontSize: desktop ? "48px" : "32px", fontFamily: "Alphakind" }}>
            Investment successful !
          </Typography>
          {/* <Typography color='primary' sx={{ marginBottom: "20px", fontSize: desktop ? "32px" : "24px", fontFamily: "Nunito" }}>
            Check your emails
          </Typography> */}
          <Typography sx={{ fontFamily: "Nunito", fontSize: desktop ? "32px" : "24px", marginBottom: "10px" }}>Thank you!</Typography>
          <Typography sx={{ fontFamily: "Nunito", fontSize: desktop ? "20px" : "16px", marginBottom: "5px" }}>
            Once confirmed, the MLP team will start a verification process to confirm reception of the {isCrypto(currency) ? (currency === 'xtz' ? 'usdt/c' : currency.toUpperCase()) : `SEPA bank`} transfer
            associated with your unique investor ID
          </Typography>
          <Typography sx={{ fontFamily: "Nunito", fontSize: desktop ? "20px" : "16px", marginBottom: "5px" }}>
            This process can take several days after the end of private sale
          </Typography>
          <Typography sx={{ fontFamily: "Nunito", fontSize: desktop ? "20px" : "16px", marginBottom: "5px" }}>
            Once validated, you will receive an email confirming your investment and the amount of MLC tokens to be claimed
          </Typography>
          <Typography sx={{ fontFamily: "Nunito", fontSize: desktop ? "20px" : "16px", marginBottom: "5px" }}>
            The claim can only occur after MyLovelyCoin listing, planned for summer 2023
          </Typography>
          <Typography sx={{ fontFamily: "Nunito", fontSize: desktop ? "20px" : "16px", marginBottom: "5px" }}>
            If for any reason your payment cannot be confirmed, we will contact you by email to solve this issue or reimburse the payment received
          </Typography>
        </div>
      ) : null}
      <Button
        component='button'
        size='small'
        variant='outlined'
        onClick={handleClose}
        sx={{ position: "fixed", top: "20px", right: "20px", color: "white", borderColor: "white" }}
      >
        x
      </Button>

      {step === 0 ? (
        <Button component='button' variant='contained' color='primary' sx={{ marginTop: "15px", marginLeft: "10px" }} onClick={goToNextStep}>
          Next
        </Button>
      ) : null}
      {step === 1 ? (
        <div style={{ display: "flex" }}>
          <Button component='button' variant='contained' color='primary' sx={{ marginTop: "15px" }} onClick={goToPreviousStep}>
            Go back
          </Button>
        </div>
      ) : null}
      {step === 2 ? (
        <Button component='button' variant='contained' color='primary' sx={{ marginTop: "15px" }} onClick={handleClose}>
          Close
        </Button>
      ) : null}
      {step !== 0 && step !== 2 ? (
        <Button sx={{ visibility: "hidden", marginTop: "15px" }} component='button' variant='contained' color='primary' onClick={handleClose}>
          Close
        </Button>
      ) : null}
    </Dialog>
  );
};
