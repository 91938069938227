import React from "react";
import Typography from "@mui/material/Typography";
import arrow from "../assets/images/Vector.svg";
import useMediaQuery from "@mui/material/useMediaQuery";

interface YoutubeVideoProp {
  embedId: string;
  title?: string;
}

export const YoutubeVideo: React.FC<YoutubeVideoProp> = ({ embedId, title }) => {
  const desktop = useMediaQuery("(min-width:600px)");
  return (
    <div
      style={{
        maxWidth: "75%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "80px",
        marginBottom: "140px",
      }}
    >
      <Typography
        color='white'
        style={{
          fontFamily: "Alphakind",
          fontWeight: 400,
          fontSize: desktop ? "24px" : "18px",
          width: "300px",
          opacity: 0.5,
          transform: desktop ? "rotate(-7deg) translateX(-90px)" : "rotate(-7deg) translateX(-25px)",
          alignSelf: "start",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "40px",
        }}
      >
        {title}
        <img src={arrow} alt='arrow' style={{ height: "25px", width: "25px", transform: "rotate(15deg)" }} />
      </Typography>
      <div className='video-responsive' style={{ maxWidth: "100%", display: "flex", justifyContent: "center" }}>
        <iframe
          width={desktop ? "560" : "auto"}
          height={desktop ? "315" : "auto"}
          src={`https://www.youtube.com/embed/${embedId}`}
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};
