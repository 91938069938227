import React from "react";
import Typography from "@mui/material/Typography";

import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import StarIcon from "@mui/icons-material/Star";
import { Countdown } from "./Countdown";

interface RoundProps {
  title: string;
  nbSpots: number;
  totalSpots: number;
  round: number;
  openingDate: string;
  closingDate: string;
  status: string;
  MLCPrice: number;
  discountPercent: string;
  handleOpenRegisterWhiteListMenu: (round?: number, close?: boolean) => void;
}

export const Round: React.FC<RoundProps> = ({
  title,
  nbSpots,
  totalSpots,
  round,
  openingDate,
  closingDate,
  status,
  MLCPrice,
  discountPercent,
  handleOpenRegisterWhiteListMenu,
}) => {
  const desktop = useMediaQuery("(min-width:600px)");

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "start", justifyContent: "center", width: "100%" }}>
        <div className='loadbar' style={{ marginLeft: desktop ? "30%" : "15%" }}>
          {/* <div className={status === "opened" || status === "completed" ? "circle" : "circle-closed"} /> */}
          <div className={"circle"} />
          {title === "Next round" ? <div className={"circle-closed"} style={{ top: "100%" }} /> : null}
          <strong
            className={status === "opened" || status === "completed" ? "bar" : "bar-closed"}
            style={{ height: title === "Next round" ? `${(100 * nbSpots) / totalSpots}%` : `${(100 * totalSpots) / totalSpots}%` }}
          ></strong>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "60px",
            alignItems: "start",
            width: desktop ? "50%" : "60%",
            opacity: status === "opened" ? 1 : 0.5,
            transform: "translateY(-20px)",
          }}
        >
          {status === "opened" ? (
            <Typography
              style={{ color: "white", fontSize: desktop ? "32px" : "24px", fontFamily: "Alphakind" }}
            >{`${title} is open for Subscriptions`}</Typography>
          ) : status === "completed" ? (
            <Typography style={{ color: "white", fontSize: desktop ? "32px" : "24px", fontFamily: "Alphakind" }}>{`${title} (closed)`}</Typography>
          ) : (
            <Typography style={{ color: "white", fontSize: desktop ? "32px" : "24px", fontFamily: "Alphakind" }}>{`${title} (opening soon)`}</Typography>
          )}
          {closingDate && status === "opened" ? (
            <Typography
              style={{
                color: "white",
                fontSize: desktop ? "24px" : "18px",
                fontFamily: "Nunito",
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              Time left before closing:
            </Typography>
          ) : null}
          {closingDate && status === "opened" ? (
            <Box sx={{ color: "white", transform: desktop ? "translateX(-15px)" : "translateX(-10px)" }}>
              <Countdown date={new Date(closingDate)} />
            </Box>
          ) : null}

          <List sx={{ width: "100%", color: "white", fontFamily: "Nunito" }} color='primary'>
            <ListItem sx={{ paddingLeft: 0 }}>
              <ListItemIcon>
                <StarIcon sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontFamily: "Nunito", fontSize: desktop ? "1rem" : "0.85rem" }}
                color='primary'
                primary={`${discountPercent}% discount on public price`}
              />
            </ListItem>
            <ListItem sx={{ paddingLeft: 0 }}>
              <ListItemIcon>
                <StarIcon sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontFamily: "Nunito", fontSize: desktop ? "1rem" : "0.85rem" }}
                color='primary'
                primary='1 000€ min investment'
              />
            </ListItem>
            <ListItem sx={{ paddingLeft: 0 }}>
              <ListItemIcon>
                <StarIcon
                  sx={{ color: title === "Current round" ? "#82C44C" : "white", fontWeight: title === "Current round" ? "bold" : "normal" }}
                />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontFamily: "Nunito",
                  fontSize: desktop ? "1rem" : "0.85rem",
                  color: title === "Current round" ? "#82C44C" : "white",
                  fontWeight: title === "Current round" ? "bold" : "normal",
                }}
                primary={`Price of $MLC : ${MLCPrice} €`}
              />
            </ListItem>
          </List>
        </div>
      </Box>
    </div>
  );
};
