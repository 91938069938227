import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import useMediaQuery from "@mui/material/useMediaQuery";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

interface FormProps {
  round: number;
  investPage?: boolean;
  goToNextStep?: () => void;
}

const isCrypto = (currency: string) => currency === "xtz" || currency === "eth" || currency === "matic"

export const Form: React.FC<FormProps> = ({ round, investPage, goToNextStep }) => {
  const [email, setEmail] = useState<String>();
  const [emailConfirmation, setEmailConfirmation] = useState<String>();
  const [password, setPassword] = useState<string>();
  const [passwordConfirmation, setPasswordConfirmation] = useState<String>();
  const [passwordError, setPasswordError] = useState("");
  const [firstName, setFirstName] = useState<String>("");
  const [lastName, setLastName] = useState<String>("");
  const [phoneNumber, setPhoneNumber] = useState<String>("");
  const [invitationCode, setInvitationCode] = useState<String>("");
  const [investment, setInvestment] = useState<Number>();
  const [checked, setChecked] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("start");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [existingEmail, setExistingEmail] = useState("");
  const [currency, setCurrency] = useState("€");
  const [xtzAddress, setXtzAddress] = useState("");

  const desktop = useMediaQuery("(min-width:600px)");
  let strongPassword = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})");

  useEffect(() => {
    if (status === "error") {
      setTimeout(() => {
        setStatus("start");
      }, 2000);
    }
  }, [status]);

  const handleCheckConditions = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleAddInvestmentPromise = async () => {
    try {
      setPasswordError("");
      if (
        !firstName ||
        !lastName ||
        !email ||
        !emailConfirmation ||
        !phoneNumber ||
        !investment ||
        !currency ||
        !checked ||
        !round ||
        !password ||
        !passwordConfirmation
      ) {
        setPasswordError("Please fill all required fields");
        setStatus("error");
        return;
      }
      if (email !== emailConfirmation) {
        setPasswordError("Emails do not match");
        setStatus("error");
        return;
      }
      if (investment < 1000 && !isCrypto(currency)) {
        setPasswordError("Investment must be at least 1000");
        setStatus("error");
        return;
      }

      if (password !== passwordConfirmation) {
        setPasswordError("Passwords do not match");
        setStatus("error");
        return;
      }

      if (!strongPassword.test(password)) {
        setPasswordError("Password is too weak");
        setStatus("error");
        return;
      }

      setLoading(true);

      const register = await fetch(`${process.env.REACT_APP_API_URL}/registerWhiteList`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          phoneNumber,
          investment,
          currency,
          invitationCode,
          round,
          password,
          xtzAddress,
        }),
      });
      const registerResponse = await register.json();

      if (registerResponse.data === "codeNotFound" || registerResponse.data === "maxUsageLimit" || registerResponse.data === "existingEmail") {
        if (registerResponse.data === "codeNotFound") {
          setError("Invalid code");
        }
        if (registerResponse.data === "maxUsageLimit") {
          setError("Code expired");
        }
        if (registerResponse.data === "existingEmail") {
          setExistingEmail("Email already registered");
        }
      } else {
        setStatus(registerResponse.data);
      }
      if (registerResponse.data === "success" && goToNextStep) {
        goToNextStep();
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setStatus("error");
    }
  };

  const inputStyle = {
    WebkitBoxShadow: "0 0 0 1000px #171535 inset",
    backgroundColor: "#171535 !important",
    backgroundClip: "content-box !important",
  };

  // @ts-ignore
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      {status === "start" ? (
        <Typography
          color='primary'
          style={{ maxWidth: "75%", textAlign: "center", fontFamily: "Nunito", fontSize: desktop ? "48px" : "32px", lineHeight: "34px" }}
        >
          Register to the whitelist
        </Typography>
      ) : (
        <div style={{ marginTop: "25px" }} />
      )}

      <Box
        component='form'
        style={{ marginTop: "25px", padding: "30px 40px", borderRadius: 10, maxWidth: "70%" }}
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
      >
        {status === "start" ? (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div>
              <TextField
                id='emailField'
                variant='outlined'
                required
                error={existingEmail ? true : false}
                helperText={existingEmail ? existingEmail : ""}
                label='Email'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value.toLowerCase())}
                value={email}
                color='secondary'
                inputProps={{ style: inputStyle }}
                key='emailField'
              />

              <TextField
                id='emailConfirmationField'
                variant='outlined'
                required
                error={emailConfirmation && email !== emailConfirmation ? true : false}
                helperText={emailConfirmation && email !== emailConfirmation ? "Emails do not match" : ""}
                label='Confirm your email'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmailConfirmation(e.target.value.toLowerCase())}
                value={emailConfirmation}
                color='secondary'
                inputProps={{ style: inputStyle }}
                key='emailConfirmationField'
              />
            </div>
            <div>
              <TextField
                id='firstNameField'
                label='First name'
                required
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
                value={firstName}
                color='secondary'
                inputProps={{ style: inputStyle }}
                key='firstNameField'
              />
              <TextField
                id='lastNameField'
                label='Last name'
                required
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
                value={lastName}
                color='secondary'
                inputProps={{ style: inputStyle }}
                key='lastNameField'
              />
            </div>
            <TextField
              id='phoneNumberField'
              color='secondary'
              label='Phone number'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value)}
              value={phoneNumber}
              required
              inputProps={{ style: inputStyle }}
              key='phoneNumberField'
            />

            <TextField
              id='outlined-number'
              color='secondary'
              label='Wished investment'
              type='number'
              required
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*", style: inputStyle }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInvestment(parseFloat(e.target.value))}
              value={investment}
              error={investment && !isCrypto(currency) && investment < 1000 ? true : false}
              helperText={
                investment && !isCrypto(currency) && investment < 1000 ? "The private sale rounds are open only for invest tickets above 1000 €" : ""
              }
              key='outlined-number'
            />
            <FormControl sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <FormLabel id='currency'>Currency</FormLabel>
              <RadioGroup row aria-labelledby='currency' onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCurrency(e.target.value)} value={currency}>
                <FormControlLabel value='€' control={<Radio color='secondary' />} label={<Typography variant='h5'>€</Typography>} />
                <FormControlLabel value='$' control={<Radio color='secondary' />} label={<Typography variant='h5'>$</Typography>} />
                <FormControlLabel value='eth' control={<Radio color='secondary' />} label={<Typography variant='h5'>eth</Typography>} />
                <FormControlLabel value='matic' control={<Radio color='secondary' />} label={<Typography variant='h5'>matic</Typography>} />
                <FormControlLabel value='xtz' control={<Radio color='secondary' />} label={<Typography variant='h5'>usdt / usdc</Typography>} />
              </RadioGroup>
              {currency === "eth" ? (
                <TextField
                  id='xtzAddress'
                  color='secondary'
                  label='eth address'
                  required={currency === "eth"}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setXtzAddress(e.target.value)}
                  value={xtzAddress}
                  inputProps={{ style: inputStyle }}
                  key="xtzAddress"
                />
              ) : null}
              {currency === "matic" ? (
                <TextField
                  id='xtzAddress'
                  color='secondary'
                  label='matic address'
                  required={currency === "matic"}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setXtzAddress(e.target.value)}
                  value={xtzAddress}
                  inputProps={{ style: inputStyle }}
                  key="xtzAddress"
                />
              ) : null}
              {currency === "xtz" ? (
                <TextField
                  id='xtzAddress'
                  color='secondary'
                  label='usdt or usdc address'
                  required={currency === "xtz"}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setXtzAddress(e.target.value)}
                  value={xtzAddress}
                  inputProps={{ style: inputStyle }}
                  key='xtzAddress'
                />
              ) : null}
            </FormControl>

            <div>
              <TextField
                id='passwordField'
                color='secondary'
                label='Password'
                type='password'
                error={password && !strongPassword.test(password) ? true : false}
                helperText={
                  password && !strongPassword.test(password)
                    ? "Password must have one lowercase and one uppercase letter, one digit, one special character and be at least 8 characters long "
                    : ""
                }
                required
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                value={password}
                inputProps={{ style: inputStyle }}
                key='passwordField'
              />
              <TextField
                id='passwordConfirmationField'
                color='secondary'
                label='Confirm your password'
                type='password'
                error={passwordConfirmation && password !== passwordConfirmation ? true : false}
                helperText={passwordConfirmation && password !== passwordConfirmation ? "Passwords do not match" : ""}
                required
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPasswordConfirmation(e.target.value)}
                value={passwordConfirmation}
                inputProps={{ style: inputStyle }}
                key='passwordConfirmationField'
              />
            </div>
            <TextField
              id='invitationCodeField'
              error={error ? true : false}
              helperText={error ? error : ""}
              label='Invitation code (optional)'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInvitationCode(e.target.value)}
              value={invitationCode}
              inputProps={{ style: inputStyle }}
              key='invitationCodeField'
            />
            <FormControlLabel
              style={{ width: "100%", marginBottom: "20px", display: "flex", justifyContent: "center" }}
              control={<Checkbox color='primary' checked={checked} onChange={handleCheckConditions} />}
              label={
                <Typography>
                  You have read and accepted our{" "}
                  <a href='https://www.mylovelyplanet.org/private-sale-terms-and-conditions' target='_blank' rel='noreferrer' style={{ color: "white" }}>
                    terms and conditions
                  </a>
                </Typography>
              }
            />
            {!loading ? (
              <Button variant='contained' style={{ marginTop: 5 }} color='primary' onClick={handleAddInvestmentPromise}>
                Register
              </Button>
            ) : (
              <CircularProgress color='primary' />
            )}
          </div>
        ) : status === "error" ? (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Typography variant='h3' color='primary' style={{ marginTop: "50px ", fontFamily: "Nunito" }}>
              {passwordError || "Oops something went wrong, please try again"}
            </Typography>
          </div>
        ) : null}
      </Box>
    </div>
  );
};
